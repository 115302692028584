import * as React from "react";
import { VStack, useColorModeValue } from "@chakra-ui/react";
/* import { theme } from './Theme'; */

const FullScreenSection = ({ children, isDarkBackground, id, gap, ...boxProps }) => {

    const bg = useColorModeValue('black', 'white')
    /* const color = useColorModeValue('white', 'black') */

    return (
        <section id={id} className="menuSections">
            <VStack
                backgroundColor=/* {boxProps.backgroundColor} */{bg}
                color={isDarkBackground ? "white" : "black"}/* {color} */
            >
                <VStack /* maxWidth="1280px" */ minHeight="100svh" gap={gap}{...boxProps}>
                    {children}
                </VStack>
            </VStack>
        </section>
    );
};
export default FullScreenSection;