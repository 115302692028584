/* import React, {useEffect, useRef, useState} from "react"; */
import "./Footer.css";
import { Heading, Image, VStack, HStack, Text /* useColorModeValue */ } from "@chakra-ui/react";

import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GSDevTools } from "gsap/GSDevTools";


gsap.registerPlugin(
  useGSAP,
  ScrollTrigger,
  GSDevTools);


const Footer = (props) => {

  useGSAP(
    () => {
      /* gsap.registerEffect({
        name:"popUp",
        extendTimeline:true,
        defaults:{
          y:"5vh",
          scale:1,
          duration:0.2,
          fade:0.2,
          ease:"expo.inOut",
          stagger:{
            each:0.25,
            ease:"power1.out",
            from:"start",
          },
        }, 
        effect: (targets, config) => {

          let tl = gsap.timeline()
          tl.from(targets, {
            autoAlpha:0,
            y:config.y,
            scale:config.scale,
            duration:config.duration,
            ease:config.ease,
            stagger:{
              each:config.staggerEach,
              ease:config.staggerEase,
              from:config.staggerFrom,
            },
          })
          tl.from(targets, {
            duration:config.fade,
            ease:config.ease,
            stagger:{
              each:config.staggerEach,
              ease:config.staggerEase,
              from:config.staggerFrom,
            },
          }, "<")
        return tl
        }
      }) */

      /* == GSAP Socials == */

      gsap.set("#socials, #footerCopyright, #footeraDmH1", {autoAlpha:0});
      gsap.fromTo("#socials", {
        autoAlpha:0,
        y: "5vw",
      },
      {
        autoAlpha:1,
        y: "0vw",
        ease: "back.out",
        duration: 0.2,
        stagger: 0.25,
        scrollTrigger: {
          trigger: "#footerHeader",
          start: 'bottom 103%',
          markers:false,
          toggleActions: 'play none none reverse',
        },
      });
      gsap.fromTo("#footerCopyright", {
        autoAlpha:0,
        y: "5vw",
      },
      {
        autoAlpha:1,
        y: "0vw",
        ease: "back.out",
        duration: 0.2,
        scrollTrigger: {
          trigger: "#footerHeader",
          start: 'bottom 101%',
          markers:false,
          toggleActions: 'play none none reverse',
        },
      });
      gsap.fromTo("#footeraDmH1", {
        autoAlpha:0,
        y: "5vw",
      },
      {
        autoAlpha:1,
        y: "0vw",
        ease: "back.out",
        duration: 0.2,
        scrollTrigger: {
          trigger: "#footerHeader",
          start: 'bottom 102%',
          markers:false,
          toggleActions: 'play none none reverse',
        },
      });

      
    },
    { dependencies: [], revertOnUpdate: true, }
  );

  return (
    <section id="footer-section">
      <VStack
        backgroundColor="transparent"//{props.bgColor}
        /* color="white" */
        /* paddingTop="3%" */
        /* alignItems="center"
        justifyContent="center" */
        spacing={0}
        zIndex="200"
        width="100%"
        position="relative"
        /* top="-2px" */
        //paddingBottom={{ base: "100px"}}
      >
        <div className="footer">

          <section id="footerHeader" className="footerHeader">

            <div id="footerCopyright">
              <h2>All rights reserved 2024 © André d'Melo</h2>
            </div>

            <div id="footeraDm">
              <div id="socialicons">
                <ul>
                  <li id="socials" flex-direction="row">
                    <Text as="a" href="https://github.com/andreDmelo/" title="Github" target="_blank" rel="noopener" border="3px solid #fff" borderColor='#ffffff33'>
                        <img src={"media/icons/github-mark-"+'white'/* props.colorName */+".svg"} data-src={"media/icons/github-mark-"+'white'/* props.colorName */+".svg"} alt="github" /* className=" lazyloaded" */></img>
                    </Text>
                  </li>
                  <li id="socials">
                    <Text as="a" href="https://www.linkedin.com/in/andreDmelo/" title="LinkedIn" target="_blank" rel="noopener" border="3px solid #fff" borderColor='#ffffff33'>
                        <img src={"media/icons/linkedin-"+'white'/* props.colorName */+".svg"} data-src={"media/icons/linkedin-"+'white'/* props.colorName */+".svg"} alt="linkedin" /* className="lazyloaded" */></img>
                    </Text>
                  </li>
                </ul>{/* 
                <ul>
                  <li>
                    <a href="https://stackoverflow.com/users/22890044/andre1melo/" title="stack overflow" target="_blank" rel="noopener">
                      <img src="images/icons/stackoverflow.svg" data-src="images/icons/stackoverflow.svg" alt="" className=" lazyloaded"></img>
                    </a>
                  </li>
                </ul> */}
              </div>
              <h1 id="footeraDmH1">André d'Melo</h1>
              {/* <Image src="./media/64405965.webp" boxSize="40px" /> */}
            </div>

            <div id="footerRight">
              {/* <VStack>
              <h2>
                Experience
              </h2>
              <h2>Projects</h2>
              <h2>Contact</h2>
              </VStack> */}
            </div>
            {/* <h2> © </h2>
            <h2>2023-2024</h2> */}

          </section>
          
        </div>
      </VStack>
    </section>
  );
};

export default Footer;