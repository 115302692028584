import { useRef, useState, useEffect } from 'react';
import "./Home.css";
import { Box, useColorMode, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
/* import { Avatar, Heading, VStack, Stack, Box, Image, useColorModeValue } from "@chakra-ui/react";
import { theme } from './Theme'; */

/* import Header from "./Header"; */

import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { CustomWiggle } from "gsap/CustomWiggle";
import { CustomEase } from "gsap/CustomEase";
import { GSDevTools } from "gsap/GSDevTools";
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
import { TextPlugin } from "gsap/TextPlugin";
import SplitType from 'split-type'

import ToasterProviders from '../lib/providers/toasterProviders.tsx'

import ToggleButton from "./Toggle";
import Nav from "./Nav";
//import LXParallax from './LXParallax';
import Hero from './Hero';
import About from './About';
import Projects from './Projects';
import Contact from "./Contact.tsx"
import Footer from "./Footer"
/* import SmallPlaneWithBanner from "./SmallPlaneWithBanner";
import SmallPlaneWithBanner2 from "./SmallPlaneWithBanner2"; */
/* import KnockUpStreamSVG2 from "./KnockUpStreamSVG2";
import KnockUpStreamSVG from "./KnockUpStreamSVG"; */
/* import Lab from "./Lab" */

gsap.registerPlugin(
  useGSAP,
  ScrollTrigger,
  ScrollSmoother,
  ScrollToPlugin,
  Draggable,
  InertiaPlugin,
  SplitText,
  ScrambleTextPlugin,
  TextPlugin,
  CustomEase,
  CustomWiggle,
  GSDevTools);

const Home = () => {
  // Detecting the rendering engine
  let isWebKit = navigator.userAgent.indexOf('AppleWebKit') > -1;
  let isGecko = navigator.userAgent.indexOf('Gecko') > -1 && navigator.userAgent.indexOf('KHTML') === -1;
  let isBlink = navigator.userAgent.indexOf('Chrome') > -1; // Blink is used by Chrome and Edge

  /* if (isWebKit) {
    console.log('The browser is using the WebKit rendering engine');
  } else if (isGecko) {
    console.log('The browser is using the Gecko rendering engine '+isGecko);
  } else if (isBlink) {
    console.log('The browser is using the Blink rendering engine');
  } else {
    console.log('The rendering engine is unknown');
  } */

  /* const wrapper = useRef(); */
  const main = useRef();
  const smoother = useRef();
  
  /* ===== Theme Colors ===== */
  const {colorMode, toggleColorMode} = useColorMode();
  const color = useColorModeValue('#ffffff', '#000000')
  const colorName = useColorModeValue('white', 'black')
  const halfColor = useColorModeValue('rgba(255, 255, 255, 0.5)', 'rgba(0, 0, 0, 0.75)')
  const bgColor = useColorModeValue('#000000', '#ffffff')
  const socialBorderColor = useColorModeValue('#ffffff33', '#00000033')
  const currentTheme = useColorModeValue('Night', 'Day')
  /* const colorHover = useColorModeValue('#fff', '#000') */
  /* const headerBG = useColorModeValue('#00000080', '#ffffff80') */
  /* const currentTheme = useColorModeValue('Dark', 'Light') */

  /* ===== Toggle Dark/Light ===== */
  const [isDark, setDark] = useState(false);
  /* const delayToggleColorMode = () => {
    setDark((prev) => !prev);
    //console.log(isDark)
    setTimeout(() => {
      //toggleColorMode();
      //ScrollTrigger.refresh();
    }, 350)
  } */

  /* ===== Media Queries ===== */
  const [noHover] = useMediaQuery('(hover: none)')
  const [portrait] = useMediaQuery('(orientation: portrait)')
  const [isUnder1536] = useMediaQuery('(max-width: 1536px)')
  const [isUnder1280] = useMediaQuery('(max-width: 1280px)')
  const [isUnder1024] = useMediaQuery('(max-width: 1024px)')
  const [under992] = useMediaQuery('(max-width: 992px)')
  const [under768] = useMediaQuery('(max-width: 767px)')
  const [hUnder376] = useMediaQuery('(max-height: 376px)')
  
  /* ===== Mobile Gecko Querie ===== */
  function mobileGecko() {
    return noHover
    ? (isGecko ? true : false )
    : false
  }


  /* ===== Refresh ScrollTrigger ===== */
  useEffect(() => {
    const scrollTriggerRefresh = () => {
      ScrollTrigger.refresh();
      //console.log("scrollTriggerRefresh")
    };
    const reloadPageOnOrientationChange = () => {
      // Clear local storage, session storage, and cookies
      localStorage.clear();
      sessionStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      window.location.href = window.location.href; // Force a full page reload
    };

    if (!noHover) {
      window.addEventListener('resize', scrollTriggerRefresh);
    }
    window.addEventListener('orientationchange', reloadPageOnOrientationChange);
    return () => {
      if (!noHover) {
        window.removeEventListener('resize', scrollTriggerRefresh);
      }
      window.removeEventListener('orientationchange', reloadPageOnOrientationChange);
    };
  }, []);

  /* ===== Space BG ===== */
    /* const theme = useColorModeValue('Dark', 'Dark');
    const aboutBG = "url('../media/space-"+theme+".webp') fixed no-repeat 100% 100%/ cover;";
    const aboutBGMobile = "url('../media/space-"+theme+".webp') no-repeat center/ cover;";
    function aboutBGdm() {
        return noHover
        ? aboutBGMobile
        : aboutBG
    }
    const aboutSectionBG = aboutBGdm(); */


  
  function hoverORtap() {
      return noHover
      ? "(Tap me)"
      : "(Hover me)"
    }
  const hoverORtapPJS = hoverORtap();


  /* gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
  let currentScrollPosSS = 0;
  let smoother = ScrollSmoother.create({
    wrapper: "#smooth-wrapper",
    content: "#smooth-content",
    smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true, // looks for data-speed and data-lag attributes on elements
    smoothTouch: false, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    // normalizeScroll: true
    onUpdate: (self) => {
      //console.log("progress", self.progress);
      let currentScrollPosSS = self.progress;
      console.log("progress", currentScrollPosSS);
    },
  }); */
  let currentScrollPosSS = 0;

/* ===== GSAP React ===== */
  useGSAP(
    () => {

      /* ScrollTrigger.normalizeScroll(true);
      ScrollTrigger.config({ ignoreMobileResize: true }); */

      let matchMedia = gsap.matchMedia();
      matchMedia.add("(hover: none)", () => {
        // this setup code only runs when its a touch device with no Hover function
        //console.log("mobile")
        smoother.current = ScrollSmoother.create({
          wrapper: "#smooth-wrapper",
          content: "#smooth-content",
          smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
          effects: true, // looks for data-speed and data-lag attributes on elements
          smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
          /* normalizeScroll: true, */
          ignoreMobileResize: true,
          onUpdate: (self) => {
            //console.log("progress", self.progress);
          },
        });
        return () => { // optional
          // custom cleanup code here (runs when it STOPS matching)
          //console.log("desktop")
        };
      });

      //let mediaWallAssets = document.querySelectorAll(".mediaWallAsset");
      //let skewSetter = gsap.quickTo(mediaWallAssets, "skewY"); // fast
      //let skew = gsap.quickTo(mediaWallAssets, "skew"); // fast
      //let clamp = gsap.utils.clamp(-10, 10); // don't let the skew go beyond 20 degrees.

      //gsap.set(mediaWallAssets, {transformPerspective:800})

      smoother.current = ScrollSmoother.create({
        wrapper: "#smooth-wrapper",
        content: "#smooth-content",
        smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        //normalizeScroll: true,
        //ignoreMobileResize: true,
        //onUpdate: (self) => {
          //console.log("progress", self.progress);
          //console.log(clamp(self.getVelocity() / -50));
          //console.log(self.getVelocity() / -50);
          //skewSetter(clamp(self.getVelocity() / -50));
          //gsap.set(mediaWallAssets, {perspective:"100px"});
            //let value =  clamp(self.getVelocity() / -50);
            //gsap.to(mediaWallAssets, {rotationX: value});
          //gsap.to(mediaWallAssets, {rotationY: value});
        //},
        //onStop: () => gsap.to(mediaWallAssets, {rotationX: 0})//skewSetter(0)
      });

      //window.smoother = smoother.current


      // Snap to section when close enough (15%)
      const sections = gsap.utils.toArray(".snapSection");

      sections.forEach((section, index, ignoreSections) => {
        //const snapSection = gsap.timeline({paused:true})
          //window.smoother.scrollTo(section);
          //.to(smoother.current, {scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.current.offset(section)), duration: 0.5,})
        //let offset = smoother.current.offset(section, "top top");

        const sectionsSnap = gsap.timeline({
          scrollTrigger: {
            trigger: section,
            start: 'top 12%',
            //end: 'top top',
            scrub: false,
            preventOverlaps:true,
            markers:false,
            invalidateOnRefresh:false,
            fastScrollEnd: 3000,
            //wrapper: ".scrollMain",
            onEnter:(self)=> {
              if (self.getVelocity() < 2000 && mobileGecko() == false) {
                //This was the best solution as only a restart immediatly after play will not produce jumps
                //snapSection.play();
                //snapSection.restart();
                smoother.current.scrollTo(section, true);
                //gsap.to(window, { duration: 0.5, scrollTo: { y: section }, ease: "power2.inOut" });
                //gsap.to(smoother.current, {scrollTop: Math.min(ScrollTrigger.maxScroll(window), smoother.current.offset(section)), duration: 0.5,})
                //console.log("Snapped to "+section.id);
                //gsap.to(smoother.current, { scrollTop: offset, duration: 1, });
              } else{
              };
            },
          }
        })
      });

      /* gsap.to(sections, {
        scrollTrigger: {
          //trigger:'#about-top',
          //start:'top 30%',
          markers:true,
          snap: {
            snapTo: 1 / (sections.length - 1),
            duration: {min: 0.2, max: 0.8},
            onStart: (self) => {console.log("onStart");},
            onInterrupt: (self) => {console.log("onInterrupt");},
            onComplete: (self) => {console.log("onComplete");},
          },
          //wrapper: "#smooth-content",
        }
      }); */

      /* let panels = gsap.utils.toArray(".snapSection"),
        snaps = [], // will store just the starting scroll value of each of the panels
        panelTriggers = panels.map((panel, i) => {
          return ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            onRefresh: (self) => (
              snaps[i] = self.start
              ),
            onUpdate: self => console.log("progress", self.progress),
            markers: {startColor: "white", endColor: "red"},
          });
        });

      ScrollTrigger.create({
        // you can add a trigger and start/end values if you'd like to limit the snapping to only part of the page. 
        snap(progress, self, direction) {
          let totalDistance = self.end - self.start,
              snapProgress = snaps.map((v) => v / totalDistance);
          return ScrollTrigger.snapDirectional(snapProgress)(progress, self.direction);
        }
      }); */

      
      /* const start = smoother.current.scrollTrigger.addEventListener("scrollStart", () => console.log("scrolling started!"));
      const end = smoother.current.scrollTrigger.addEventListener("scrollEnd", () => console.log("scrolling ended!")); */


      /* ===== Blur Plugin ===== */
      (function() {
        const blurProperty = gsap.utils.checkPrefix("filter"),
              blurExp = /blur\((.+)?px\)/,
              getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];
      
        gsap.registerPlugin({
          name: "blur",
          get(target) {
            return +(getBlurMatch(target)[1]) || 0;
          },
          init(target, endValue) {
            let data = this,
                filter = gsap.getProperty(target, blurProperty),
                endBlur = "blur(" + endValue + "px)",
                match = getBlurMatch(target)[0],
                index;
            if (filter === "none") {
              filter = "";
            }
            if (match) {
              index = filter.indexOf(match);
              endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
            } else {
              endValue = filter + endBlur;
              filter += filter ? " blur(0px)" : "blur(0px)";
            }
            data.target = target; 
            data.interp = gsap.utils.interpolate(filter, endValue); 
          },
          render(progress, data) {
            data.target.style[blurProperty] = data.interp(progress);
          }
        });
      })();


      /* == a1mWarpIn Effect == */
      gsap.registerEffect({
        name:"a1mWarpIn",
        extendTimeline:true,
        defaults:{
          rotationX:gsap.utils.wrap([90, 45, -45, -90, -45, 45]),
          rotationY:25,
          x:"3vw",
          y:0,
          transformOrigin: '50% 50% -10',
          scale:1,
          duration:0.7,
          fade:0.7,
          ease:"expo.inOut",
          stagger:{
            each:0.075,
            ease:"power1.out",
            /* from:"start", */
          },
        }, 
        effect: (targets, config) => {
          
          let tl = gsap.timeline()
          tl.from(targets, {
            autoAlpha:0,
            rotationX:config.rotationX,
            rotationY:config.rotationY,
            x:config.x,
            y:config.y,
            transformOrigin:config.transformOrigin,
            scale:config.scale,
            duration:config.duration,
            ease:config.ease,
            stagger:{
              each:config.staggerEach,
              ease:config.staggerEase,
              /* from:config.staggerFrom, */
            },
          })
          tl.from(targets, {
            duration:config.fade,
            ease:config.ease,
            stagger:{
              each:config.staggerEach,
              ease:config.staggerEase,
            },
          }, "<")
        return tl
        }
      })

      /* == Reveal Titles == */

      const splitTypesT = gsap.utils.toArray('.gsap-reveal-t');
      splitTypesT.forEach((element, index) => {
        let split = new SplitText(element, {types: 'chars' });
        const titlesAnim = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top 95%',
            end: 'top 95%',
            scrub: false,
            toggleActions: 'play none none none',
          }})
          .a1mWarpIn(split.chars, {x:"4vw", scale:1, staggerEach:0.065})

        ScrollTrigger.create({
          trigger:element,
          start: 'top 95%',
          end: 'top 95%',
          scrub:false,
          animation: titlesAnim,
          toggleActions:"none none none reverse",
        })
      });

      /* == Reveal Paragraphs Headers == */
      const splitTypesH = gsap.utils.toArray('.gsap-reveal-h');
      /* const staggerFromWrap = gsap.utils.wrap(["start", "random", "end"]) */
      splitTypesH.forEach((headers, i) => {
        const text = new SplitType(headers, { types: 'words' });
        gsap.set(text.words, {opacity:0});
        const headersAnim = gsap.fromTo(
          text.words,
          {
            opacity: 0,
            rotationX: 90,
          },
          {
            opacity: 1,
            rotationX: 0,
            transformOrigin: '50% 100% 0',
            ease: "power2.in",
            //duration: 0.5,
            stagger:{ each:0.1, from:"start" },
            scrollTrigger: {
              trigger: headers,
              start: 'top bottom',
              end: 'top 55%',
              scrub: true,
              markers: false,
              toggleActions: 'play none none none',
              invalidateOnRefresh: true, // This is what makes the ScrollTrigger work on mobile browsers with the dynamic viewport size
            },
          }
        );
        /* ScrollTrigger.create({
          trigger:headers,
          start: 'top 90%',
          scrub:false,
          animation:headerssAnim,
          toggleActions:"none none none reverse",
        }) */
      });

      /* == Reveal Paragraphs == */
      const splitTypesP = gsap.utils.toArray('.gsap-reveal-p');
      /* const staggerFromWrap = gsap.utils.wrap(["start", "random", "end"]) */
      splitTypesP.forEach((paragraph, i) => {
        const text = new SplitType(paragraph, { types: 'words' });
        gsap.set(text.words, {opacity:0});
        const paragraphsAnim = gsap.fromTo(
          text.words,
          {
            opacity: 0,
            y: '8vh',
            scale: '0.25',
            rotate:"10deg",
            rotationX:-75,
          },
          {
            opacity: 1,
            y: '0vh',
            scale: 1,
            rotate:"0deg",
            rotationX:0,
            /* rotationY:25, */
            transformOrigin: '50% 50% -50',
            duration: 0.75,
            stagger:{each:0.02, from:"random"},
            scrollTrigger: {
              trigger: paragraph,
              start: 'top bottom',
              end: 'top 65%',
              scrub: true,
              markers: false,
              toggleActions: 'play none none none',
              invalidateOnRefresh: true,
            },
          }
        );
        /* ScrollTrigger.create({
          trigger:paragraph,
          start: 'top 90%',
          scrub:false,
          animation:paragraphsAnim,
          toggleActions:"none none none reverse",
        }) */
      });



      /* == Reveal Logos == */
      const logosGroup = gsap.utils.toArray("#gsap-reveal-logos");
      logosGroup.forEach((item, index) => {
        item.childNodes.forEach((itemChild) => {
          gsap.set(itemChild, {
            autoAlpha:0
          });
        });
        const logosAnim = gsap.fromTo(item.childNodes, {
          autoAlpha:0,
          translateY: '5vw',
          /* transformOrigin: '50% 50% -10', */
        },
        {
          autoAlpha:1,
          translateY: '0vw',
          /* transformOrigin: '50% 50% -10', */
          duration: 0.35,
          stagger: {each:0.1, from:"center"},
          scrollTrigger: {
            trigger: item,
            start: 'top 95%',
            end: 'top 95%',
            /* end: screenDetect4ScrollTrigger(), */
            scrub: false,
            markers: false,
            /* scroller: wrapper.current, */
            toggleActions: 'play none none none',
          },
        });
        ScrollTrigger.create({
          trigger:item,
          start: 'top 95%',
          end: 'top 95%',
          scrub:false,
          markers: false,
          animation:logosAnim,
          toggleActions:"none none none reverse",
        })
      });





      /* == Contact Section == */

      /* == GSAP Forms == */
      const formsGroup = gsap.utils.toArray(".contact-forms");
      formsGroup.forEach((form, index) => {
        gsap.fromTo(form, {
          autoAlpha:0,
          x: "-5vw",
        },
        {
          autoAlpha:1,
          x: "0vw",
          ease: "back.out",
          duration: 0.25,
          scrollTrigger: {
            trigger: form,
            start: 'top 95%',
            end: 'top 95%',
            markers:false,
            toggleActions: 'play play reverse reverse',
          },
        });
      });

      /* function killAllRestart{
        ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
        init()
      } */


/* GSDevTools.create(); */

    },
    { dependencies: [color, noHover, portrait, under768, isGecko, /* lettersYInit, lettersYLiftoff, lettersYDrop, lettersYRise */], revertOnUpdate: true, scope: main }
  );

  /* function init() {
    gsap.set(".smooth-wrapper", {autoAlpha:1})
    //console.log(currentScrollPosSS)
  } */
  /* const AndreMeloSVG = () => (
    <svg x="0px" y="0px" viewBox="0 0 2073.2 400" style="enable-background:new 0 0 2073.2 400;">
      <g>
        <path id="A" d="M173.2,342.4l-10.8-54h-66l-10.4,54H0L81.2,64h98.4l80.8,278.4H173.2z M108,230.8h42.8l-21.6-109.2L108,230.8z"/>
        <path id="n" d="M449,137.6c10.3,11.2,15.4,26.9,15.4,47.2v157.6h-79.2V199.2c0-8-1-13.5-3-16.4c-2-2.9-5-4.4-9-4.4
          c-8.8,0-17.7,7.1-26.8,21.2v142.8h-79.2V128.8h69.2l5.6,22c9.9-10.4,19.9-18,30.2-22.8c10.3-4.8,21.8-7.2,34.6-7.2
          C424.7,120.8,438.7,126.4,449,137.6z"/>
        <path id="d" d="M702.8,44.8v297.6h-70.4l-2.8-22c-15.2,20-35.3,30-60.4,30c-26.9,0-47.2-10.3-60.8-30.8
          c-13.6-20.5-20.4-48.9-20.4-85.2c0-21.9,3.7-41.4,11.2-58.6c7.5-17.2,17.9-30.7,31.4-40.4c13.5-9.7,29-14.6,46.6-14.6
          c18.4,0,33.9,5.6,46.4,16.8V36.8L702.8,44.8z M623.6,274.8v-85.6c-3.7-4-7.5-7-11.2-9c-3.7-2-7.9-3-12.4-3
          c-9.3,0-16.8,4.6-22.4,13.8s-8.4,23.9-8.4,44.2c0,23.5,2.3,39.1,7,46.8c4.7,7.7,11.3,11.6,19.8,11.6
          C607.2,293.6,616.4,287.3,623.6,274.8z"/>
        <path id="r" d="M887.6,125.6L875.2,202c-7.2-2.1-13.7-3.2-19.6-3.2c-11.7,0-20.5,3.9-26.2,11.6c-5.7,7.7-10.3,19.3-13.8,34.8v97.2
          h-79.2V128.8h69.2l6.4,40c4.5-14.1,11.7-25.5,21.6-34c9.9-8.5,20.7-12.8,32.4-12.8C872.9,122,880.1,123.2,887.6,125.6z"/>
        <path id="e" d="M1088,258H958.4c2.1,14.4,6.6,24.1,13.4,29c6.8,4.9,16.6,7.4,29.4,7.4c7.5,0,15.1-1.5,22.8-4.6
          c7.7-3.1,16.3-7.8,25.6-14.2l32,43.2c-25.9,21.1-55.2,31.6-88,31.6c-37.9,0-66.5-10.5-86-31.4c-19.5-20.9-29.2-48.6-29.2-83
          c0-21.3,4.1-40.7,12.2-58.2c8.1-17.5,20.2-31.3,36.2-41.6c16-10.3,35.3-15.4,58-15.4c21.1,0,39.5,4.4,55.2,13.2
          c15.7,8.8,27.9,21.6,36.6,38.4c8.7,16.8,13,36.9,13,60.4C1089.6,239.5,1089.1,247.9,1088,258z M1012.8,0l29.6,57.6L942,97.2
          l-21.2-39.6L1012.8,0z M1011.6,210.4c-0.3-12.5-2.2-22.3-5.8-29.2c-3.6-6.9-10.1-10.4-19.4-10.4c-8.8,0-15.4,3.1-19.8,9.2
          c-4.4,6.1-7.3,17.2-8.6,33.2h53.6V210.4z"/>
        <path id="M" d="M1492.8,342.4h-81.2l-2-98.8c-0.3-7.7-0.4-19.3-0.4-34.8c0-27.2,1.1-53.5,3.2-78.8l-32.8,184.4h-78L1266.4,130
          c3.5,37.1,5.2,68.8,5.2,95.2v18l-2,99.2h-81.2l20-278.4H1310l32,180.4L1370.8,64h102L1492.8,342.4z"/>
        <path id="e_00000044176535413342476920000013043640122234757271_" d="M1715.2,258h-129.6c2.1,14.4,6.6,24.1,13.4,29
          c6.8,4.9,16.6,7.4,29.4,7.4c7.5,0,15.1-1.5,22.8-4.6c7.7-3.1,16.3-7.8,25.6-14.2l32,43.2c-25.9,21.1-55.2,31.6-88,31.6
          c-37.9,0-66.5-10.5-86-31.4c-19.5-20.9-29.2-48.6-29.2-83c0-21.3,4.1-40.7,12.2-58.2c8.1-17.5,20.2-31.3,36.2-41.6
          c16-10.3,35.3-15.4,58-15.4c21.1,0,39.5,4.4,55.2,13.2c15.7,8.8,27.9,21.6,36.6,38.4c8.7,16.8,13,36.9,13,60.4
          C1716.8,239.5,1716.2,247.9,1715.2,258z M1638.8,210.4c-0.3-12.5-2.2-22.3-5.8-29.2c-3.6-6.9-10.1-10.4-19.4-10.4
          c-8.8,0-15.4,3.1-19.8,9.2c-4.4,6.1-7.3,17.2-8.6,33.2h53.6V210.4z"/>
        <path id="l" d="M1753.8,332.2c-11.6-12.1-17.4-29.3-17.4-51.4v-236l79.2-8v240.8c0,7.2,3.2,10.8,9.6,10.8c2.9,0,5.7-0.5,8.4-1.6
          l14,56.4c-12.5,4.8-27.2,7.2-44,7.2C1782,350.4,1765.4,344.3,1753.8,332.2z"/>
        <path id="o" d="M2037,150.8c19.6,20,29.4,48.4,29.4,85.2c0,22.9-4.5,43.1-13.4,60.4c-8.9,17.3-21.7,30.7-38.2,40
          c-16.5,9.3-36,14-58.4,14c-34.1,0-61-10-80.6-30c-19.6-20-29.4-48.4-29.4-85.2c0-22.9,4.5-43.1,13.4-60.4
          c8.9-17.3,21.7-30.7,38.2-40c16.5-9.3,36-14,58.4-14C1990.5,120.8,2017.4,130.8,2037,150.8z M1934.4,191.2
          c-4.5,9.1-6.8,23.7-6.8,44c0,21.1,2.3,36.1,6.8,45c4.5,8.9,11.9,13.4,22,13.4c10.1,0,17.5-4.5,22-13.6c4.5-9.1,6.8-23.7,6.8-44
          c0-21.1-2.3-36.1-6.8-45c-4.5-8.9-11.9-13.4-22-13.4C1946.2,177.6,1938.9,182.1,1934.4,191.2z"/>
      </g>
    </svg>
  ); */

  /* const ASVG = () => (
    <svg viewBox="0 0 260.4 278.4" width="6vw" height="auto" style={{ fill: "#c70002", preserveAspectRatio:"xMidYMid meet" }}>
      <path id="A" data-name="A" d="m173.2,278.4l-10.8-54h-66l-10.4,54H0L81.2,0h98.4l80.8,278.4h-87.2Zm-65.2-111.6h42.8l-21.6-109.2-21.2,109.2Z"></path>
  </svg>
  ); */
  
  /* window.addEventListener("load", init) */
  /* window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  } */


  /* ----- Footer Animation ----- */
  useGSAP(
    () => {
      let contractMainWrapper4FooterScale = portrait ? ( under768 ? 0.9 :  0.9) : 0.95;

      const contractMainWrapper4Footer = gsap.timeline({paused:true})
        .set("#mainWrapper", {scale: 1})
        .fromTo("#mainWrapper", {scale: 1}, {scale: contractMainWrapper4FooterScale, transformOrigin: "50% 100%", duration: 5, ease:CustomEase.create("custom", "M0,0 C0.149,0 0.79,0.698 1,1 ")}, 0)
        .fromTo("#mainWrapper", {borderBottomRightRadius: "0vw", borderBottomLeftRadius: "0vw"}, {borderBottomRightRadius: "40px", borderBottomLeftRadius: "40px", duration: 5, ease:"power3.out"}, 0)

      ScrollTrigger.create({
        trigger: "#footer-section",
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
        preventOverlaps:true,
        markers:false,
        invalidateOnRefresh:true,
        //fastScrollEnd: 3000,
        //animation: contractMainWrapper4Footer,  // This solution was causing a bug where on page load "#mainWrapper" was "scale: 0.95"
        onUpdate: (self) => {
          const progress = self.progress;
          contractMainWrapper4Footer.progress(progress).pause();
        },
      })

    },
    { dependencies: [portrait, under768], revertOnUpdate: true, }
  );

  useEffect(() => {

    // Add event listener for custom event
    const handleProjectsArchiveToggled = () => {
      console.log("Received event: projectsArchiveToggled");
      setTimeout(() => {
        ScrollTrigger.refresh();
        console.log("ScrollTrigger refreshed");
      }, 500); // Adjust the timeout as needed
    };

    window.addEventListener('projectsArchiveToggled', handleProjectsArchiveToggled);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('projectsArchiveToggled', handleProjectsArchiveToggled);
      //contract4Footer.kill();
    };
  }, []);

  return (
    <>
      <ToasterProviders>
      <Nav smoother={smoother} color={color} bgColor={bgColor}/>
      <div id="smooth-wrapper" ref={main}>
        <div id="smooth-content">
          <div class="noise__overlay"></div>
          <div id="mainWrapper">
          {/* <div className="wrapper menuSections" id="wrapper-section" ref={wrapper}> */}

            {/* <div className="theme_switch">
              <ToggleButton
                isDark={!isDark}
                invertedIconLogic
                onChange={() => {}}
                onClick={() => {setDark((prev) => !prev)}}
              />
            </div> */}

            <div className="scrollParallax"></div>
            {/* <div className="scrollAndreMelo"></div> */}


            {/* <div className="scrollMain"></div> */}

            {/* <LXParallax hoverORtapPJS={hoverORtapPJS} color={color} bgColor={bgColor} colorName={colorName} noHover={noHover} portrait={portrait} under768={under768} isUnder1536={isUnder1536} isUnder1280={isUnder1280} isUnder1024={isUnder1024} isGecko={isGecko}/> */}

            <Hero hoverORtapPJS={hoverORtapPJS} color={color} bgColor={bgColor} colorName={colorName} noHover={noHover} portrait={portrait} under768={under768} isUnder1536={isUnder1536} isUnder1280={isUnder1280} isUnder1024={isUnder1024} isGecko={isGecko}/>

            <About hoverORtapPJS={hoverORtapPJS} color={color} bgColor={bgColor} colorName={colorName} /* aboutSectionBG={aboutSectionBG} */ noHover={noHover} portrait={portrait} under768={under768} isUnder1536={isUnder1536} isUnder1280={isUnder1280} isUnder1024={isUnder1024} isGecko={isGecko}/>
            <Projects smoother={smoother} hoverORtapPJS={hoverORtapPJS} portrait={portrait} under768={under768} isUnder1536={isUnder1536} isUnder1280={isUnder1280} isUnder1024={isUnder1024} color={color} bgColor={bgColor}/>
            <Contact color={color} colorName={colorName} bgColor={bgColor} socialBorderColor={socialBorderColor}/>

          </div>
          <Footer color={color} bgColor={bgColor}/>
        {/* </div> */}
        </div>
      </div>
      </ToasterProviders>
    </>
  );
};

export default Home;