import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import "./Hero.css";
import { Box, useColorMode, useColorModeValue, VStack } from "@chakra-ui/react";
// import { Avatar, Heading, VStack, Stack, Box, Image, useColorModeValue } from "@chakra-ui/react";

import { useMediaQuery } from '@react-hook/media-query';

import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { ScrollSmoother } from "gsap/ScrollSmoother";
//import { ScrollToPlugin } from "gsap/ScrollToPlugin";
//import { Draggable } from "gsap/Draggable";
//import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { CustomWiggle } from "gsap/CustomWiggle";
import { RoughEase } from  "gsap/EasePack";
import { CustomEase } from "gsap/CustomEase";
import { GSDevTools } from "gsap/GSDevTools";
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin';
import { TextPlugin } from "gsap/TextPlugin";
import { Draggable } from "gsap/Draggable";
//import SplitType from 'split-type'


//import BeamPortalSVG from "./BeamPortalSVG";
import CodeWindow from "./CodeWindow";
import CodeDivider from "./CodeDivider";


import Bubble from "./Bubble";

gsap.registerPlugin(
  useGSAP,
  ScrollTrigger,
  //ScrollSmoother,
  //ScrollToPlugin,
  //Draggable,
  //InertiaPlugin,
  SplitText,
  ScrambleTextPlugin,
  TextPlugin,
  CustomEase,
  RoughEase,
  CustomWiggle,
  Draggable,
  GSDevTools);

const Hero = (props) => {

  //const [reactNativeCreativeVideo, setReactNativeCreativeVideo] = useState("");
  const [bubblesDeveloperSide, setBubblesDeveloperSide] = useState([]);
  const [bubblesCreativeSide, setBubblesCreativeSide] = useState([]);
  const currentBubbleIndexRef = useRef(0);

  const intervalRef = useRef(null); // Ref to store interval ID
  const isGeneratingBubblesRef = useRef(false); // Ref to track if bubbles generation

  useEffect(() => {
    // Clear interval on component unmount
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  /* ===== Media Queries ===== */
  const portrait = useMediaQuery('(orientation: portrait)')
  const under768 = useMediaQuery('(max-width: 767px)');
  const under1024 = useMediaQuery('(max-width: 1024px)');
  const under1280 = useMediaQuery('(max-width: 1280px)');
  const under1536 = useMediaQuery('(max-width: 1536px)');


  /* ----- Bubble Generator -----*/

  /* useEffect(() => {
    // First occurrence after 2 seconds
    const firstTimeout = setTimeout(() => {
      generateBubbles();

      // Subsequent occurrences every 5 seconds
      const interval = setInterval(() => {
        generateBubbles();
      }, 5000);

      // Clear interval on component unmount
      return () => clearInterval(interval);
    }, 2000);

    // Clear timeout on component unmount
    return () => clearTimeout(firstTimeout);
  }, []); */


  useGSAP(
    () => {
      let matchMedia = gsap.matchMedia();

      /* let reactNativeCreativeVideo = "/media/hero/ReactNative-creative.mp4";
      if (under768) {
        reactNativeCreativeVideo = "/media/hero/ReactNative-creative.640w.mp4";
      } else if (under1024) {
        reactNativeCreativeVideo = "/media/hero/ReactNative-creative.768w.mp4";
      } else if (under1280) {
        reactNativeCreativeVideo = "/media/hero/ReactNative-creative.1024w.mp4";
      } else if (under1536) {
        reactNativeCreativeVideo = "/media/hero/ReactNative-creative.1280w.mp4";
      }
      setReactNativeCreativeVideo(reactNativeCreativeVideo); */

      const codeWindowsList = [
        {
          header: "HTML",
          icon: "/media/logos/html5-original-wordmark-white.svg",
          videoRight: "/media/hero/HTML-developer.mp4",
          videoLeft: "/media/hero/HTML-creative.mp4",
          alt: "HTML code window",
        },
        /* {
          header: "CSS",
          icon: "/media/logos/css3-original-wordmark-white.svg",
          videoRight: "/media/hero/HTML-developer.mp4",
          videoLeft: "/media/hero/GSAP-creative.mp4",
          alt: "CSS code window",
        }, */
        {
          header: "Javascript",
          icon: "/media/logos/javascript-original.svg",
          videoRight: "/media/hero/JavaScript-developer.mp4",
          videoLeft: "/media/hero/JavaScript-creative.mp4",
          alt: "Javascript code window",
        },
        /* {
          header: "Typescript",
          icon: "/media/logos/Typescript.svg",
          videoRight: "/media/hero/HTML-developer.mp4",
          videoLeft: "/media/hero/GSAP-creative.mp4",
          alt: "Typescript code window",
        }, */
        {
          header: "React",
          icon: "/media/logos/react-original-wordmark.webp",
          videoRight: "/media/hero/React-developer.mp4",
          videoLeft: "/media/hero/React-creative.mp4",
          alt: "React code window",
        },
        {
          header: "Next.js",
          icon: "/media/logos/next-js-full-white.svg",
          videoRight: "/media/hero/Next.js-developer.mp4",
          videoLeft: "/media/hero/Next.js-creative.mp4",
          alt: "Next.js code window",
        },
        {
          header: "React Native",
          icon: "/media/logos/reactNative.webp",
          videoRight: "/media/hero/ReactNative-developer.mp4",
          videoLeft: "/media/hero/ReactNative-creative.mp4",
          alt: "React Native code window",
        },
        {
          header: "GSAP",
          icon: "/media/logos/GSAP+Windmill-white.svg",
          videoRight: "/media/hero/GSAP-developer.mp4",
          videoLeft: "/media/hero/GSAP-creative.mp4",
          alt: "GSAP code window",
        },
        {
          header: "Three.js",
          icon: "/media/logos/three.js-white.svg",
          videoRight: "/media/hero/Three.js-developer.mp4",
          videoLeft: "/media/hero/Three.js-creative.mp4",
          alt: "Three.js code window",
        },
        /* {
          header: "Tailwind",
          icon: "/media/logos/tailwind-css.svg",
          videoRight: "/media/hero/HTML-developer.mp4",
          videoLeft: "/media/hero/GSAP-creative.mp4",
          alt: "Tailwind code window",
        }, */
      ];

      matchMedia.add("(orientation: landscape)", () => {

        const generateBubbles = () => {
          //const targetIndex = gsap.utils.random(0, 8); // Index of the target project
          const targetHeader = codeWindowsList[currentBubbleIndexRef.current];
          //const targetHeader = codeWindowsList[gsap.utils.random(0, 9)]; // Index of the target project
          //console.log(currentBubbleIndexRef.current)

          setBubblesCreativeSide((prevBubbles) => {
            //console.log(prevBubbles.length);
            const newBubbleId = `bubbleCreativeSide${prevBubbles.length}`;
            const newBubbleVideoId = `bubbleCreativeSideVideo${prevBubbles.length}`;
            const newBubble = <Bubble key={newBubbleId} id={newBubbleId} videoID={newBubbleVideoId} header={targetHeader.header} icon={targetHeader.icon} video={targetHeader.videoLeft} alt={targetHeader.alt} />;

            let bubbleFinalMaxWidth = portrait ? ( under768 ? "50vw" :  "35vw") : "22vw";

            // Use a callback to ensure the animation runs after the state update
            setTimeout(() => {
              const currentBubble = document.getElementById(newBubbleId);
              const currentBubbleVideo = document.getElementById(newBubbleVideoId);
              if (currentBubble) {
                const heroBubbleAnim = gsap.timeline({paused:false})
                  .set(currentBubble, {'--myBubbleImgWidth': "0.1vw", '--myBubbleImgHeight': "0.1vw"})
                  .fromTo(currentBubble, {
                    opacity: 1,
                    translateX: "50vw",
                    xPercent: 0,
                    }, {
                    translateX: "0vw",
                    xPercent: -100,
                    duration: 8,
                    ease: CustomEase.create("custom", "M0,0 C0,0.206 0.064,0.277 0.5,0.6 0.751,0.786 1,0.804 1,1 "),
                    onComplete: () => {
                      currentBubble.remove();
                      heroBubbleAnim.kill();
                    },
                    }, 0)
                  .fromTo(currentBubble, {
                    translateY: gsap.utils.random(25, 70)+"vh",
                    yPercent:-50
                    }, {
                    translateY: gsap.utils.random(0, 100)+"vh",
                    yPercent: -100,
                    duration: 15,
                    ease: "rough({ template:none.in, strength: 0.2, points:5, taper:both, randomize:true, clamp:false })",
                    }, 0)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(156, 0, 0, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(200,0,0,0.75) 0%,rgba(200,0,0,0) 70%)",
                    }, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    ease: "power3.in",
                    duration: 1,
                    }, 0)
                  .fromTo(currentBubble, {
                    '--myBubbleVideoMaxWidth': "0.1vw",
                    '--myBubbleVideoHeight': "0.1vw",
                    '--myBubbleIconOpacity': 1,
                    '--myBubbleIconScale': 1,
                    }, {
                    '--myBubbleVideoMaxWidth': bubbleFinalMaxWidth,
                    '--myBubbleVideoHeight': "auto",
                    '--myBubbleIconOpacity': 0.3,
                    '--myBubbleIconScale': 1.25,
                    ease: "power2.in",
                    duration: 1.25,
                    }, 0.5)
                  .fromTo(currentBubble, {
                    borderRadius: "50rem",
                    }, {
                    borderRadius: "0.5rem",
                    ease: "power2.out",
                    duration: 1.25,
                    }, 0.65)
                  .set(currentBubbleVideo, {currentTime: 0}, 1.5)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    border: "0px solid rgba(128, 128, 128, 0.5)",
                    }, {
                    boxShadow: "0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 0px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 0%)",
                    border: "2px solid rgba(128, 128, 128, 0.5)",
                    ease: "power1.in",
                    duration: 0.5,
                    }, 1)
              }
            }, 200);

            return [...prevBubbles, newBubble];
          });


          setBubblesDeveloperSide((prevBubbles) => {
            //console.log(prevBubbles.length);
            const newBubbleId = `bubbleDeveloperSide${prevBubbles.length}`;
            const newBubbleVideoId = `bubbleDeveloperSideVideo${prevBubbles.length}`;
            const newBubble = <Bubble key={newBubbleId} id={newBubbleId} videoID={newBubbleVideoId} header={targetHeader.header} icon={targetHeader.icon} video={targetHeader.videoRight} alt={targetHeader.alt} />;

            let bubbleFinalMaxWidth = portrait ? ( under768 ? "50vw" :  "35vw") : "22vw";

            // Use a callback to ensure the animation runs after the state update
            setTimeout(() => {
              const currentBubble = document.getElementById(newBubbleId);
              const currentBubbleVideo = document.getElementById(newBubbleVideoId);
              if (currentBubble) {
                const heroBubbleAnim = gsap.timeline({paused:false})
                  .set(currentBubble, {'--myBubbleImgWidth': "0.1vw", '--myBubbleImgHeight': "0.1vw"})
                  .fromTo(currentBubble, {
                    opacity: 1,
                    translateX: "0vw",
                    xPercent: -100,
                    }, {
                    translateX: "50vw",
                    xPercent: 0,
                    duration: 8,
                    ease: CustomEase.create("custom", "M0,0 C0,0.206 0.064,0.277 0.5,0.6 0.751,0.786 1,0.804 1,1 "),
                    onComplete: () => {
                      currentBubble.remove();
                      heroBubbleAnim.kill();
                    },
                    }, 0)
                  .fromTo(currentBubble, {
                    translateY: gsap.utils.random(25, 70)+"vh",
                    yPercent:-50
                    }, {
                    translateY: gsap.utils.random(0, 100)+"vh",
                    yPercent: -100,
                    duration: 15,
                    ease: "rough({ template:none.in, strength: 0.2, points:5, taper:both, randomize:true, clamp:false })",
                    }, 0)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(156, 0, 0, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(200,0,0,0.75) 0%,rgba(200,0,0,0) 70%)",
                    }, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    ease: "power3.in",
                    duration: 1,
                    }, 0)
                  .fromTo(currentBubble, {
                    '--myBubbleVideoMaxWidth': "0.1vw",
                    //'--myBubbleVideoHeight': "auto",
                    '--myBubbleIconOpacity': 1,
                    '--myBubbleIconScale': 1,
                    }, {
                    '--myBubbleVideoMaxWidth': bubbleFinalMaxWidth,
                    //'--myBubbleVideoHeight': "auto",
                    '--myBubbleIconOpacity': 0.3,
                    '--myBubbleIconScale': 1.25,
                    ease: "power2.in",
                    duration: 1.25,
                    }, 0.5)
                  .fromTo(currentBubble, {
                    borderRadius: "50rem",
                    }, {
                    borderRadius: "0.5rem",
                    ease: "power2.out",
                    duration: 1.25,
                    }, 0.65)
                  .set(currentBubbleVideo, {currentTime: 0}, 1.5)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    border: "0px solid rgba(128, 128, 128, 0.5)",
                    }, {
                    boxShadow: "0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 0px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 0%)",
                    border: "2px solid rgba(128, 128, 128, 0.5)",
                    ease: "power1.in",
                    duration: 0.5,
                    }, 1)
              }
            }, 50);

            return [...prevBubbles, newBubble];
          });

          // Update the index to the next one, cycling back to 0 if at the end of the list
          currentBubbleIndexRef.current = (currentBubbleIndexRef.current + 1) % codeWindowsList.length;

        };

        let bubblesGenerationInterval = portrait ? ( under768 ? 5000 : 5000 ) : 5000;
        const startBubblesGeneration = () => {
          if (!isGeneratingBubblesRef.current) {
            //console.log("Starting Bubbles generation");
            isGeneratingBubblesRef.current = true;
            generateBubbles(); // Generate an initial one upon start
            intervalRef.current = setInterval(() => {
              //console.log("Generating Bubbles");
              generateBubbles();
            }, bubblesGenerationInterval);
          }
        };

        const stopBubblesGeneration = () => {
          if (isGeneratingBubblesRef.current) {
            //console.log("Stopping Bubbles generation");
            isGeneratingBubblesRef.current = false;
            clearInterval(intervalRef.current);
          }
        };

        const handleVisibilityChange = () => {
          if (document.hidden) {
            stopBubblesGeneration(); // Stop bubble generation when the tab is not active
            //console.log("document.hidden Stopping Bubbles generation");
          } else {
            startBubblesGeneration(); // Restart bubble generation when the tab is active
            //console.log("document.visible Restart Bubbles generation");
          }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        ScrollTrigger.create({
          trigger: '.aboutPanel1',
          start: 'top 40%',
          scrub: false,
          markers: false,
          //once: true,
          //toggleActions:"play none none reverse",
          //once:true,
          onEnter: ({}) => stopBubblesGeneration(),
          //onLeave: ({}) => stopBubblesGeneration(),
          //onEnterBack: () => startBubblesGeneration(),
          onLeaveBack: ({}) => startBubblesGeneration(),
          /* onToggle: ({isActive}) => {
            console.log("toggled to active:", isActive);
            if (isActive){
              console.log("resumeVideoGeneration ", isActive);
              resumeVideoGeneration()
            } else {
              console.log("pauseVideoGeneration ", isActive);
              pauseVideoGeneration()
            }
          } */
        })
        

        // First occurrence after 2 seconds
        const firstTimeout = setTimeout(() => {
          startBubblesGeneration();

          /* // Subsequent occurrences every 5 seconds
          const interval = setInterval(() => {
            generateBubbles();
          }, 5000);

          // Clear interval on component unmount
          return () => clearInterval(interval); */
        }, 2000);

        // Clear timeout on component unmount
        return () => clearTimeout(firstTimeout);

      });
      // ----- End of matchMedia orientation landscape




      // ----- matchMedia orientation portrait
      matchMedia.add("(orientation: portrait)", () => {
        const generateBubbles = () => {

          //const targetIndex = gsap.utils.random(0, 8); // Index of the target project
          const targetHeader = codeWindowsList[currentBubbleIndexRef.current];
          //console.log(currentBubbleIndexRef.current)

          setBubblesCreativeSide((prevBubbles) => {
            //console.log(prevBubbles.length);
            const newBubbleId = `bubbleCreativeSide${prevBubbles.length}`;
            const newBubbleVideoId = `bubbleCreativeSideVideo${prevBubbles.length}`;
            const newBubble = <Bubble key={newBubbleId} id={newBubbleId} videoID={newBubbleVideoId} header={targetHeader.header} icon={targetHeader.icon} video={targetHeader.videoLeft} alt={targetHeader.alt} />;

            let bubbleFinalMaxWidth = portrait ? ( under768 ? "70vw" :  "50vw") : "22vw";
            if (currentBubbleIndexRef.current == 5) {bubbleFinalMaxWidth = under768 ? "35vw" :  "25vw"} // for React Native vertical video

            // Use a callback to ensure the animation runs after the state update
            setTimeout(() => {
              const currentBubble = document.getElementById(newBubbleId);
              const currentBubbleVideo = document.getElementById(newBubbleVideoId);
              if (currentBubble) {
                const heroBubbleAnim = gsap.timeline({paused:false})
                  .set(currentBubble, {'--myBubbleImgWidth': "0.1vw", '--myBubbleImgHeight': "0.1vw"})
                  .fromTo(currentBubble, {
                    opacity: 1,
                    translateY: "50vh",
                    yPercent: 0,
                    }, {
                    translateY: "0vh",
                    yPercent: -100,
                    duration: 8,
                    ease: CustomEase.create("custom", "M0,0 C0,0.206 0.064,0.277 0.5,0.6 0.751,0.786 1,0.804 1,1 "),
                    onComplete: () => {
                      currentBubble.remove();
                      heroBubbleAnim.kill();
                    },
                    }, 0)
                  .fromTo(currentBubble, {
                    translateX: gsap.utils.random(25, 70)+"vw",
                    xPercent:-50
                    }, {
                    translateX: gsap.utils.random(0, 100)+"vw",
                    xPercent: -50,
                    duration: 10,
                    ease: "rough({ template:none.in, strength: 0.2, points:5, taper:both, randomize:true, clamp:false })",
                    }, 0)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(156, 0, 0, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(200,0,0,0.75) 0%,rgba(200,0,0,0) 70%)",
                    }, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    ease: "power3.in",
                    duration: 1,
                    }, 0)
                  .fromTo(currentBubble, {
                    '--myBubbleVideoWidth': "0.1vw",
                    '--myBubbleVideoMaxWidth': "0.1vw",
                    '--myBubbleVideoHeight': "0.1vw",
                    '--myBubbleIconOpacity': 1,
                    '--myBubbleIconScale': 1,
                    }, {
                    '--myBubbleVideoWidth': "min-content",
                    '--myBubbleVideoMaxWidth': bubbleFinalMaxWidth,
                    '--myBubbleVideoHeight': "auto",
                    '--myBubbleIconOpacity': 0.3,
                    '--myBubbleIconScale': 1.25,
                    ease: "power2.in",
                    duration: 1.25,
                    }, 0.5)
                  .fromTo(currentBubble, {
                    borderRadius: "50rem",
                    }, {
                    borderRadius: "0.5rem",
                    ease: "power2.out",
                    duration: 1.25,
                    }, 0.65)
                  .set(currentBubbleVideo, {currentTime: 0}, 1.5)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    border: "0px solid rgba(128, 128, 128, 0.5)",
                    }, {
                    boxShadow: "0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 0px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 0%)",
                    border: "2px solid rgba(128, 128, 128, 0.5)",
                    ease: "power1.in",
                    duration: 0.5,
                    }, 1)
              }
            }, 200);

            return [...prevBubbles, newBubble];
          });

          setBubblesDeveloperSide((prevBubbles) => {
            //console.log(prevBubbles.length);
            const newBubbleId = `bubbleDeveloperSide${prevBubbles.length}`;
            const newBubbleVideoId = `bubbleDeveloperSideVideo${prevBubbles.length}`;
            const newBubble = <Bubble key={newBubbleId} id={newBubbleId} videoID={newBubbleVideoId} header={targetHeader.header} icon={targetHeader.icon} video={targetHeader.videoRight} alt={targetHeader.alt} />;

            let bubbleFinalMaxWidth = portrait ? ( under768 ? "70vw" :  "50vw") : "22vw";

            // Use a callback to ensure the animation runs after the state update
            setTimeout(() => {
              const currentBubble = document.getElementById(newBubbleId);
              const currentBubbleVideo = document.getElementById(newBubbleVideoId);
              if (currentBubble) {
                const heroBubbleAnim = gsap.timeline({paused:false})
                  .set(currentBubble, {'--myBubbleImgWidth': "0.1vw", '--myBubbleImgHeight': "0.1vw"})
                  .fromTo(currentBubble, {
                    opacity: 1,
                    translateY: "0vh",
                    yPercent: -100,
                    }, {
                    translateY: "50vh",
                    yPercent: 0,
                    duration: 8,
                    ease: CustomEase.create("custom", "M0,0 C0,0.206 0.064,0.277 0.5,0.6 0.751,0.786 1,0.804 1,1 "),
                    onComplete: () => {
                      currentBubble.remove();
                      heroBubbleAnim.kill();
                    },
                    }, 0)
                  .fromTo(currentBubble, {
                    translateX: gsap.utils.random(25, 70)+"vw",
                    xPercent:-50
                    }, {
                    translateX: gsap.utils.random(0, 100)+"vw",
                    xPercent: -50,
                    duration: 10,
                    ease: "rough({ template:none.in, strength: 0.2, points:5, taper:both, randomize:true, clamp:false })",
                    }, 0)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(156, 0, 0, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(200,0,0,0.75) 0%,rgba(200,0,0,0) 70%)",
                    }, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    ease: "power3.in",
                    duration: 1,
                    }, 0)
                  .fromTo(currentBubble, {
                    '--myBubbleVideoMaxWidth': "0.1vw",
                    //'--myBubbleVideoHeight': "auto",
                    '--myBubbleIconOpacity': 1,
                    '--myBubbleIconScale': 1,
                    }, {
                    '--myBubbleVideoMaxWidth': bubbleFinalMaxWidth,
                    //'--myBubbleVideoHeight': "auto",
                    '--myBubbleIconOpacity': 0.3,
                    '--myBubbleIconScale': 1.25,
                    ease: "power2.in",
                    duration: 1.25,
                    }, 0.5)
                  .fromTo(currentBubble, {
                    borderRadius: "50rem",
                    }, {
                    borderRadius: "0.5rem",
                    ease: "power2.out",
                    duration: 1.25,
                    }, 0.65)
                  .set(currentBubbleVideo, {currentTime: 0}, 1.5)
                  .fromTo(currentBubble, {
                    boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%)",
                    border: "0px solid rgba(128, 128, 128, 0.5)",
                    }, {
                    boxShadow: "0 0px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 0px rgba(255, 255, 255, 1)",
                    '--myBubbleAfterBackground': "radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 0%)",
                    border: "2px solid rgba(128, 128, 128, 0.5)",
                    ease: "power1.in",
                    duration: 0.5,
                    }, 1)
              }
            }, 50);

            return [...prevBubbles, newBubble];
          });


          // Update the index to the next one, cycling back to 0 if at the end of the list
          currentBubbleIndexRef.current = (currentBubbleIndexRef.current + 1) % codeWindowsList.length;

        };

        let bubblesGenerationInterval = portrait ? ( under768 ? 5000 : 5000 ) : 5000;
        const startBubblesGeneration = () => {
          if (!isGeneratingBubblesRef.current) {
            //console.log("Starting Bubbles generation");
            isGeneratingBubblesRef.current = true;
            generateBubbles(); // Generate an initial one upon start
            intervalRef.current = setInterval(() => {
              //console.log("Generating Bubbles");
              generateBubbles();
            }, bubblesGenerationInterval);
          }
        };

        const stopBubblesGeneration = () => {
          if (isGeneratingBubblesRef.current) {
            //console.log("Stopping Bubbles generation");
            isGeneratingBubblesRef.current = false;
            clearInterval(intervalRef.current);
          }
        };

        const handleVisibilityChange = () => {
          if (document.hidden) {
            stopBubblesGeneration(); // Stop bubble generation when the tab is not active
            //console.log("document.hidden Stopping Bubbles generation");
          } else {
            startBubblesGeneration(); // Restart bubble generation when the tab is active
            //console.log("document.visible Restart Bubbles generation");
          }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        ScrollTrigger.create({
          trigger: '.aboutPanel1',
          start: 'top 40%',
          scrub: false,
          markers: false,
          //once: true,
          //toggleActions:"play none none reverse",
          //once:true,
          onEnter: ({}) => stopBubblesGeneration(),
          //onLeave: ({}) => stopBubblesGeneration(),
          //onEnterBack: () => startBubblesGeneration(),
          onLeaveBack: ({}) => startBubblesGeneration(),
          /* onToggle: ({isActive}) => {
            console.log("toggled to active:", isActive);
            if (isActive){
              console.log("resumeVideoGeneration ", isActive);
              resumeVideoGeneration()
            } else {
              console.log("pauseVideoGeneration ", isActive);
              pauseVideoGeneration()
            }
          } */
        })
        

        // First occurrence after 2 seconds
        const firstTimeout = setTimeout(() => {
          startBubblesGeneration()

          /* // Subsequent occurrences every 5 seconds
          const interval = setInterval(() => {
            generateBubbles();
          }, 5000);

          // Clear interval on component unmount
          return () => clearInterval(interval); */
        }, 2500);

        // Clear timeout on component unmount
        return () => clearTimeout(firstTimeout);

      });
      // ----- End of matchMedia orientation portrait


    
    },
    { dependencies: [under768, under1024, under1280, under1536], revertOnUpdate: true }
  );


  /* const generateBubble = () => {
    const targetIndex = 1; // Index of the target project
    const targetHeader = codeWindowsList.at(targetIndex);

    setBubbles((prevBubbles) => [
      ...prevBubbles,
      <Bubble key={prevBubbles.length} id={"bubble"+prevBubbles.length} header={targetHeader.header} />,
    ]);
    let currentBubbleID = {"bubble"+(prevBubbles)};
    let currentBubble = document.getElementById(currentBubbleID);
    useGSAP(
      () => {
        const heroBubbleAnim = gsap.timeline({paused:false})
          .fromTo('#', {translateX: "0vw", xPercent: -100, translateY: "40vh", yPercent:50}, {translateX: "50vw", xPercent: 0, translateY: "60vh", yPercent: -50, duration: 20, ease: "circle.out"}, 0)
        //GSDevTools.create({animation: heroBubbleAnim});
        },
        { dependencies: [], revertOnUpdate: true }
      );
  }; */




  /* ===== GSAP React ===== */
  useGSAP(
    () => {
      let matchMedia = gsap.matchMedia();

      //Draggable.create(".heroTitle")


      //gsap.set('#andreWrapper', {translateY: "20vh", yPercent:-50, translateX: "50vw", xPercent:-100})
      //gsap.set('#meloWrapper', {translateY: "22vh", yPercent:-50, translateX: "50vw", xPercent:0})
      //gsap.set('#creativeWrapper', {translateY: "76vh", yPercent:-50, translateX: "50vw", xPercent:-100})
      //gsap.set('#developerWrapper', {translateY: "78vh", yPercent:-50, translateX: "50vw", xPercent:0})
      //gsap.set('#codeWindow1', {translateY: "50vh", yPercent:-50})
      //gsap.set('#andre, #melo, #creative, #developer', {autoAlpha:1})
      gsap.set('#creative, #developer', {autoAlpha:1})

      //gsap.set('.bubble', {scale:0.75, translateY: "50vh", yPercent:-50, translateX: "30vw", xPercent:-50})

      //let andreSplit = new SplitText("#andre", {types: 'chars' });
      //let meloSplit = new SplitText("#melo", {types: 'chars' });
      let creativeSplit = new SplitText("#creative", {types: 'chars' });
      let developerSplit = new SplitText("#developer", {types: 'chars' });

      matchMedia.add("(orientation: landscape)", () => {

        /* == a1mWarpIn Effect == */
        gsap.registerEffect({
          name:"a1mWarpIn",
          extendTimeline:true,
          defaults:{
            rotationX:gsap.utils.wrap([90, 45, -45, -90, -45, 45]),
            rotationY:25,
            x:"3vw",
            y:0,
            transformOrigin: '50% 50% -10',
            scale:1,
            duration:0.7,
            fade:0.7,
            ease:"expo.inOut",
            stagger:{
              each:0.075,
              ease:"power1.out",
              from:"start",
            },
          }, 
          effect: (targets, config) => {

            let tl = gsap.timeline()
            tl.from(targets, {
              autoAlpha:0,
              rotationX:config.rotationX,
              rotationY:config.rotationY,
              x:config.x,
              y:config.y,
              transformOrigin:config.transformOrigin,
              scale:config.scale,
              duration:config.duration,
              ease:config.ease,
              stagger:{
                each:config.staggerEach,
                ease:config.staggerEase,
                from:config.staggerFrom,
              },
            })
            tl.from(targets, {
              duration:config.fade,
              ease:config.ease,
              stagger:{
                each:config.staggerEach,
                ease:config.staggerEase,
                from:config.staggerFrom,
              },
            }, "<")
          return tl
          }
        })

        gsap.set('#creativeWrapper', {translateY: "49vh", yPercent:-50, translateX: "50vw", xPercent:-100})
        gsap.set('#developerWrapper', {translateY: "51vh", yPercent:-50, translateX: "50vw", xPercent:0})

        const heroBeamAnim = gsap.timeline({paused:true})
          //.fromTo('#portal1', {translateX: "-0.25vw"}, {translateX: "0.25vw", duration:3.5, repeat:-1, yoyo: true, yoyoEase: true}, 0)
          .fromTo('#portal1', {height: "75vh"}, {height: "70vh", duration:2, repeat:-1, yoyo: true, yoyoEase: true}, 0)
          .fromTo('#portal2', {translateX: "0.25vw", scaleX: 0.8, opacity: 0.85}, {translateX: "-0.25vw", scaleX: 1.15, opacity: 0.65, duration:3, ease: "power2.in", repeat:-1, yoyo: true, yoyoEase: "power2.out"}, 0)
          //.fromTo('#portal3', {translateX: "-1vw", scaleX: 1}, {translateX: "1vw", scaleX: 1.25, duration:2, ease: "power1.inOut", repeat:-1, yoyo: true, yoyoEase: "power1.inOut"}, 0)
          .fromTo('#portal3', {opacity: 0.85}, {opacity: 0.25, duration:2, ease: "power2.inOut", repeat:-1, yoyo: true, yoyoEase: "power1.inOut"}, 0)
          .fromTo('#portal3', {translateY: "2vh"}, {translateY: "-2vh", duration:3, ease: "power2.inOut", repeat:-1, yoyo: true, yoyoEase: "power2.inOut"}, 0)

        const heroIntroAnim = gsap.timeline({paused:false})
          //.set('#codeWindow1', {autoAlpha:0}, 0)
          //.set('#portal1, #portal2, #portal3', {autoAlpha:0})
          .from('#portal1, #portal2, #portal3', {width: "0vw", autoAlpha:0, duration:0.2, stagger:0.1}, 0.5)
          //.fromTo('#andre', {translateX: "0vw", xPercent:100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, 0.3)
          //.fromTo(andreSplit.chars, {color: "rgb(96, 0, 0)"}, {color: "rgb(215, 215, 215)", duration:0.75, ease: "power1.in"}, 0.3)
          //.a1mWarpIn(andreSplit.chars, {x:"15vw", scale:0.75, duration:0.75, staggerEach:0.1}, "<")
          //.fromTo('#melo', {translateX: "0vw", xPercent:-100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, "-=0.75")
          //.fromTo(meloSplit.chars, {color: "rgb(96, 0, 0)"}, {color: "rgb(215, 215, 215)", duration:0.75, ease: "power1.in"}, "<")
          //.a1mWarpIn(meloSplit.chars, {x:"-15vw", scale:0.75, duration:0.75, staggerEach:0.1, staggerFrom:"end"}, "<")
          .fromTo('#creative', {translateX: "0vw", xPercent:100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, "-=0.55")
          .fromTo(creativeSplit.chars, {color: "rgb(96, 0, 0)"}, {color: "rgba(255, 255, 255, 0.7)", duration:0.75, ease: "power1.in"}, "<")
          .a1mWarpIn(creativeSplit.chars, {x:"15vw", scale:0.75, duration:0.5, staggerEach:0.08}, "<")
          .fromTo('#developer', {translateX: "0vw", xPercent:-100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1, onComplete: () => {
            heroBeamAnim.play()
          }}, "-=0.6")
          .fromTo(developerSplit.chars, {color: "rgb(96, 0, 0)"}, {color: "rgba(255, 255, 255, 0.7)", duration:0.75, ease: "power1.in"}, "<")
          .a1mWarpIn(developerSplit.chars, {x:"-15vw", scale:0.75, duration:0.5, staggerEach:0.08, staggerFrom:"end"}, "<")
          //.fromTo('#codeWindow1', {translateX: "0vw", xPercent:-100, autoAlpha:1}, {translateX: "50vw", xPercent:-50, autoAlpha:1, duration:5}, "-=0.25")
          //.fromTo('#codeWindow1', {translateX: "100vw", xPercent:0, autoAlpha:1}, {translateX: "0vw", xPercent:-100, autoAlpha:1, duration:8}, "-=0.15")
      });

      matchMedia.add("(orientation: portrait)", () => {

        /* == a1mWarpIn Effect == */
        gsap.registerEffect({
          name:"a1mWarpIn",
          extendTimeline:true,
          defaults:{
            rotationY:gsap.utils.wrap([90, 45, -45, -90, -45, 45]),
            rotationX:25,
            y:"3vh",
            x:0,
            transformOrigin: '50% 50% -10',
            scale:1,
            duration:0.7,
            fade:0.7,
            ease:"expo.inOut",
            stagger:{
              each:0.075,
              ease:"power1.out",
              from:"start",
            },
          }, 
          effect: (targets, config) => {

            let tl = gsap.timeline()
            tl.from(targets, {
              autoAlpha:0,
              rotationX:config.rotationX,
              rotationY:config.rotationY,
              x:config.x,
              y:config.y,
              transformOrigin:config.transformOrigin,
              scale:config.scale,
              duration:config.duration,
              ease:config.ease,
              stagger:{
                each:config.staggerEach,
                ease:config.staggerEase,
                from:config.staggerFrom,
              },
            })
            tl.from(targets, {
              duration:config.fade,
              ease:config.ease,
              stagger:{
                each:config.staggerEach,
                ease:config.staggerEase,
                from:config.staggerFrom,
              },
            }, "<")
          return tl
          }
        })

        gsap.set('#creativeWrapper', {translateY: "50vh", yPercent:-100, translateX: "50vw", xPercent:-50})
        gsap.set('#developerWrapper', {translateY: "50vh", yPercent:0, translateX: "50vw", xPercent:-50})

        let portal1FinalWidth = portrait ? ( under768 ? "95vw" :  "90vw") : "22vw";
        let portal1FinalWidthVariant = portrait ? ( under768 ? "85vw" :  "85vw") : "22vw";

        const heroBeamAnim = gsap.timeline({paused:true})
        .fromTo('#portal1', {width: portal1FinalWidth}, {width: portal1FinalWidthVariant, duration:2, repeat:-1, yoyo: true, yoyoEase: true}, 0)
        .fromTo('#portal2', {scaleY: 0.7}, {scaleY: 1.15, duration:4, ease: "power2.in", repeat:-1, yoyo: true, yoyoEase: "power2.out"}, 0)
        .fromTo('#portal3', {scaleY: 1}, {scaleY: 1.35, duration:2, ease: "power1.inOut", repeat:-1, yoyo: true, yoyoEase: "power1.inOut"}, 0)
        .fromTo('#portal3', {scaleX: 1}, {scaleY: 0.9, duration:4, ease: "power2.inOut", repeat:-1, yoyo: true, yoyoEase: "power2.inOut"}, 0)

        const heroIntroAnim = gsap.timeline({paused:false})
          .from('#portal1, #portal2, #portal3', {height: "0vw", autoAlpha:0, duration:0.2, stagger:0.1}, 0.65)
          //.fromTo('#creative', {yPercent:100, autoAlpha:0.75}, {yPercent:0, autoAlpha:1}, "-=0.55")
          .fromTo(creativeSplit.chars, {color: "rgb(96, 0, 0)"}, {color: "rgba(255, 255, 255, 0.7)", duration:0.75, ease: "power1.in"}, "-=0.2")
          .a1mWarpIn(creativeSplit.chars, {y:"15vh", scale:0.75, duration:0.5, staggerEach:0.08}, "<")
          /* .fromTo('#developer', {yPercent:-100, autoAlpha:0.75}, {yPercent:0, autoAlpha:1, onComplete: () => {
            heroBeamAnim.play()
          }}, "-=0.6") */
          .fromTo(developerSplit.chars, {color: "rgb(96, 0, 0)"}, {color: "rgba(255, 255, 255, 0.7)", duration:0.75, ease: "power1.in", onComplete: () => {
            heroBeamAnim.play()
          }}, "-=0.65")
          .a1mWarpIn(developerSplit.chars, {y:"-15vh", scale:0.75, duration:0.5, staggerEach:0.08, staggerFrom:"end"}, "<")


        //Show/Hide Hero on scroll
        const HeroFadeInOut = gsap.timeline({paused:true})
        .fromTo('#heroBackgroundWrapper', {opacity:1}, {opacity:0, ease:"power1.out" }, 0)
        .fromTo('#portal1, #portal2, #portal3', {autoAlpha:1}, {autoAlpha:0, ease:"power1.out"}, 0)
        .fromTo(creativeSplit.chars, {autoAlpha:1}, {autoAlpha:0, ease:"power1.out"}, 0)
        .fromTo(developerSplit.chars, {autoAlpha:1}, {autoAlpha:0, ease:"power1.out"}, 0)

        ScrollTrigger.create({
          trigger: '.aboutPanel1',
          start: 'top 60%',
          end: 'top 40%',
          scrub: true,
          fastScrollEnd: true,
          //markers: true,
          preventOverlaps: true,
          animation: HeroFadeInOut,
          //toggleActions:"play none none reverse",
          //once:true,
        })
      });



      /*
      
      
      gsap.set('#codeDivider', {translateX: "0vw", xPercent:0})
      gsap.set('#heroBeamPortalRings, #heroBeamPortalHalo, #heroBeamBlastBottom, #heroBeamBlastCenter, #heroBeam-BlastHalo1, #heroBeam-BlastHalo2, #heroBeam-Beam-0, #heroBeam-Beam-1, #heroBeam-Beam-2, #heroBeam-Beam-3, #heroBeam-Beam-4, #heroBeam-Beam-5, #heroBeam-Beam-6, #heroBeam-Beam-7, #heroBeam-Beam-8, #heroBeam-DustCloud-0, #heroBeam-DustCloud-1, #heroBeam-DustCloud-2, #heroBeam-DustCloud-3', {autoAlpha:0})
      
      const heroBeamAnim = gsap.timeline({paused:false})
      .set('#heroBeam', {translateY:"-65vh"}, 0)
      //.fromTo('#heroBeamPortalHalo', {scale:0}, {scale:1, transformOrigin: "50% 90%", ease:"power1.in", duration:0.25}, 0)
      //.fromTo('#heroBeamPortalHalo', {autoAlpha:0}, {autoAlpha:1, duration:0.2}, 0)
      //.fromTo('#heroBeamPortalHalo', {autoAlpha:1}, {autoAlpha:0, duration:0.25}, 0.2)
      //.fromTo('#heroBeamPortalRings', {autoAlpha:0, scale:0}, {autoAlpha:1, scale:1, transformOrigin: "50% 90%", ease:"linear", duration:0.1}, 0)
      .fromTo('#heroBeam', {translateY:"-65vh"}, {translateY:"28vh", ease:"sine.out", duration:0.5}, 0.19)
      .fromTo('#heroDivider', {height:"0%"}, {height:"95%", transformOrigin: "50% 80%", ease:"sine.out", duration:0.5}, 0.19)
      //.fromTo('#heroBeamBlastBottom', {autoAlpha:0}, {autoAlpha:1, duration:0.2}, 0.1)
      .fromTo('#heroBeam-BlastHalo1', {autoAlpha:0, scale:0}, {autoAlpha:1, scale:1, duration:0.2, transformOrigin: "50% 85%"}, 0.1)
      .fromTo('#heroBeamBlastCenter', {autoAlpha:0}, {autoAlpha:1, duration:0.1}, 0.15)
      .fromTo('#heroBeam-BlastHalo2', {autoAlpha:0}, {autoAlpha:1, duration:0.1}, 0.25)
      .fromTo('#heroBeam-Beam-0, #heroBeam-Beam-1, #heroBeam-Beam-2, #heroBeam-Beam-3, #heroBeam-Beam-4, #heroBeam-Beam-5, #heroBeam-Beam-6, #heroBeam-Beam-7, #heroBeam-Beam-8', {autoAlpha:0}, {autoAlpha:1, duration:0.03, stagger:0.01}, 0.15)
      //.fromTo('#heroBeam-DustCloud-2', {autoAlpha:0}, {autoAlpha:1, duration:0.15}, 0.41)
      //.fromTo('#heroBeam-DustCloud-0', {autoAlpha:0}, {autoAlpha:1, duration:0.8, ease: CustomEase.create("custom", "M0,0 C0,0 0.03,0.601 0.044,0.61 0.06,0.622 0.081,0 0.1,0 0.117,0 0.187,0.725 0.2,0.725 0.212,0.725 0.296,-0.051 0.3,0 0.305,0.074 0.38,0.6 0.4,0.6 0.43,0.6 0.487,0 0.5,0 0.514,0 0.582,0.514 0.6,0.506 0.611,0.501 0.686,0.001 0.7,0 0.733,-0.002 0.777,0.704 0.8,0.704 0.822,0.704 0.863,0 0.9,0 0.933,-0.001 1,1 1,1 ")}, 0.3)
      //.fromTo('#heroBeam-DustCloud-1', {autoAlpha:0}, {autoAlpha:1, duration:0.8, ease: CustomEase.create("custom", "M0,0 C0,0 0.03,0.601 0.044,0.61 0.06,0.622 0.081,0 0.1,0 0.117,0 0.187,0.725 0.2,0.725 0.212,0.725 0.296,-0.051 0.3,0 0.305,0.074 0.38,0.6 0.4,0.6 0.43,0.6 0.487,0 0.5,0 0.514,0 0.582,0.514 0.6,0.506 0.611,0.501 0.686,0.001 0.7,0 0.733,-0.002 0.777,0.704 0.8,0.704 0.822,0.704 0.863,0 0.9,0 0.933,-0.001 1,1 1,1 ")}, 0.25)
      //.fromTo('#heroBeam-DustCloud-2', {autoAlpha:0}, {autoAlpha:1, duration:0.8, ease: CustomEase.create("custom", "M0,0 C0,0 0.185,0.521 0.2,0.521 0.225,0.521 0.395,0.221 0.399,0.272 0.404,0.346 0.582,0.682 0.602,0.682 0.632,0.682 0.783,0.123 0.797,0.122 0.83,0.12 1,0.6 1,0.6 ")}, 0.2)
      //.fromTo('#heroBeam-DustCloud-0', {autoAlpha:0.75}, {autoAlpha:0, duration:0.15}, 1.1)
      //.fromTo('#heroBeam-DustCloud-1', {autoAlpha:0.75}, {autoAlpha:0, duration:0.15}, 1.05)
      //.fromTo('#heroBeam-DustCloud-2', {autoAlpha:0.5}, {autoAlpha:0, duration:0.15}, 1)
      .fromTo('#heroBeam-Beam-8, #heroBeam-Beam-7, #heroBeam-Beam-6, #heroBeam-Beam-5, #heroBeam-Beam-4, #heroBeam-Beam-3, #heroBeam-Beam-2, #heroBeam-Beam-1, #heroBeam-Beam-0', {autoAlpha:1}, {autoAlpha:0, duration:0.03, stagger:0.01}, 0.65)
      //.fromTo('#heroBeamBlastCenter, #heroBeam-BlastHalo2, #heroBeam-BlastHalo1, #heroBeamBlastBottom', {autoAlpha:1}, {autoAlpha:0, duration:0.03, stagger:0.01}, "-=0.05")
      .fromTo('#heroBeamBlastCenter, #heroBeam-BlastHalo2, #heroBeam-BlastHalo1', {autoAlpha:1}, {autoAlpha:0, duration:0.03, stagger:0.01}, "-=0.115")
      .fromTo('#heroBeam-BlastHalo1, #heroBeam-BlastHalo2', {scale:1}, {scale:0, duration:0.2, transformOrigin: "50% 85%"}, "-=0.115")
      //.fromTo('#heroBeamPortalHalo', {autoAlpha:1, scale:1}, {autoAlpha:0, scale:0, transformOrigin: "50% 90%", ease:"power1.in", duration:0.25}, "-=0.225")
      //.fromTo('#heroBeamPortalRings', {autoAlpha:1, scale:1}, {autoAlpha:0, scale:0, transformOrigin: "50% 90%", ease:"linear", duration:0.1}, "-=0.175")
      .fromTo('#andre', {translateX: "0vw", xPercent:100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, 0.5)
      .a1mWarpIn(andreSplit.chars, {x:"15vw", scale:0.75, duration:0.75, staggerEach:0.1}, "<")
      .fromTo('#melo', {translateX: "0vw", xPercent:-100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, "-=0.75")
      .a1mWarpIn(meloSplit.chars, {x:"-15vw", scale:0.75, duration:0.75, staggerEach:0.1, staggerFrom:"end"}, "<")
      .fromTo('#creative', {translateX: "0vw", xPercent:100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, "-=0.55")
      .a1mWarpIn(creativeSplit.chars, {x:"15vw", scale:0.75, duration:0.5, staggerEach:0.08}, "<")
      .fromTo('#developer', {translateX: "0vw", xPercent:-100, autoAlpha:0.75}, {translateX: "0vw", xPercent:0, autoAlpha:1}, "-=0.6")
      .a1mWarpIn(developerSplit.chars, {x:"-15vw", scale:0.75, duration:0.5, staggerEach:0.08, staggerFrom:"end"}, "<")
      //.fromTo('#codeWindow1', {translateX: "0vw", xPercent:-53, autoAlpha:1}, {translateX: "0vw", xPercent:53, autoAlpha:1, duration:5}, "-=0.25")
      .fromTo('#codeWindow1', {translateX: "50vw", xPercent:-150, autoAlpha:1}, {translateX: "50vw", xPercent:50, autoAlpha:1, duration:5}, "-=0.75") */

    //GSDevTools.create({animation: heroIntroAnim});

    },
    { dependencies: [], revertOnUpdate: true }
  );

  return (
    <>
      <section id="home-section" className="hero menuSections"/* background={aboutSectionBG} */>


        {/* <svg viewBox="0 0 0 0" width="0" height="0" style={{display:"none"}}>
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" /> 
              <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
              <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
            </filter>
          </defs>
        </svg> */}

        <div class="portalBG">
          <div id="portal3" class="portal portal3"></div>
        </div>

        {/* <div id="andreWrapper" className="heroTitleWrapper andreWrapper">
          <div id="andre" className="heroTitle andre">André</div>
        </div>
        <div id="meloWrapper" className="heroTitleWrapper meloWrapper">
          <div id="melo" className="heroTitle melo">deMelo</div>
        </div> */}

        <div id="heroBackgroundWrapper" className="heroBackgroundWrapper">
          <div id="heroCreativeSide" className="heroCreativeSide">
            {bubblesCreativeSide}
          </div>
          <div id="heroDeveloperSide" className="heroDeveloperSide">
            {bubblesDeveloperSide}
          </div>
            {/* <div id="" className="">HTML CSS</div> */}
            {/* <img src="/media/hero/codeWindow1.png" alt="heroBeamPortalRings"/> */}
        </div>

        <div id="creativeWrapper" className="heroTitleWrapper creativeWrapper">
          <div id="creative" className="heroTitle creative">Creative</div>
        </div>
        <div id="developerWrapper" className="heroTitleWrapper developerWrapper">
          <div id="developer" className="heroTitle developer">Developer</div>
        </div>

        {/* <div id="heroGooWrapper" className="heroGooWrapper">
          <div id="heroDivider" className="heroDivider"></div>
        </div> */}

        {/* <div id="codeWindow1" className="codeWindow"
          clipPath="polygon(0 0, 50% 0, 50% 100%, 0% 100%)"
          webkit-clip-path="polygon(0 0, 50% 0, 50% 100%, 0% 100%)">
          <img id="codeWindowR" src="/media/hero/codeWindow1.png" alt="heroBeamPortalRings" className=""/>
          //<img id="codeWindowL" src="/media/hero/codeWindow2.png" alt="heroBeamPortalRings" className="left"/>
        </div> */}


        <div class="portalFG">
          <div id="portal2" class="portal portal2">b</div>
          <div id="portal1" class="portal portal1">a</div>
        </div>


        {/* <div id="heroBeamWrapper" className="heroBeamWrapper">
          <div id="heroBeam" className="heroBeam">
              <div id="heroBeamPortalRings" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-PortalRings.webp" alt="heroBeamPortalRings"/>
              </div>
              <div id="heroBeamPortalHalo" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-PortalHalo.webp" alt="heroBeamPortalHalo"/>
              </div>
              <div id="heroBeamBlastBottom" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-BlastBottom.webp" alt="heroBeamBlastBottom"/>
              </div>
              <div id="heroBeamBlastCenter" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-BlastCenter.webp" alt="heroBeamBlastCenter"/>
              </div>
              <div id="heroBeam-BlastHalo1" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-BlastHalo.webp" alt="heroBeamBeam-BlastHalo"/>
              </div>
              <div id="heroBeam-BlastHalo2" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-BlastHalo.webp" alt="heroBeamBeam-BlastHalo"/>
              </div>
              <div id="heroBeam-Beam-0" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-Beam-0.webp" alt="heroBeam-Beam-0"/>
              </div>
              <div id="heroBeam-Beam-1" className="heroBeamLayer mix-blend-color-dodge">
                <img src="/media/hero/beam/Beam-Beam-1.webp" alt="heroBeam-Beam-1"/>
              </div>
              <div id="heroBeam-Beam-2" className="heroBeamLayer mix-blend-color-dodge">
                <img src="/media/hero/beam/Beam-Beam-2.webp" alt="heroBeam-Beam-2"/>
              </div>
              <div id="heroBeam-Beam-3" className="heroBeamLayer mix-blend-color-dodge">
                <img src="/media/hero/beam/Beam-Beam-3.webp" alt="heroBeam-Beam-3"/>
              </div>
              <div id="heroBeam-Beam-4" className="heroBeamLayer mix-blend-color-dodge">
                <img src="/media/hero/beam/Beam-Beam-4.webp" alt="heroBeam-Beam-4"/>
              </div>
              <div id="heroBeam-Beam-5" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-Beam-5.webp" alt="heroBeam-Beam-5"/>
              </div>
              <div id="heroBeam-Beam-6" className="heroBeamLayer mix-blend-overlay">
                <img src="/media/hero/beam/Beam-Beam-6.webp" alt="heroBeam-Beam-6"/>
              </div>
              <div id="heroBeam-Beam-7" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-Beam-7.webp" alt="heroBeam-Beam-7"/>
              </div>
              <div id="heroBeam-Beam-8" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-Beam-8.webp" alt="heroBeam-Beam-8"/>
              </div>
              <div id="heroBeam-DustCloud-0" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-DustCloud-0.webp" alt="heroBeam-DustCloud-0"/>
              </div>
              <div id="heroBeam-DustCloud-1" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-DustCloud-1.webp" alt="heroBeam-DustCloud-1"/>
              </div>
              <div id="heroBeam-DustCloud-2" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-DustCloud-2.webp" alt="heroBeam-DustCloud-2"/>
              </div>
              <div id="heroBeam-DustCloud-3" className="heroBeamLayer mix-blend-screen">
                <img src="/media/hero/beam/Beam-DustCloud-3.webp" alt="heroBeam-DustCloud-3"/>
              </div>
            </div>
          </div> */}

        {/* <Box className="version-container">[ Beware, website in Beta testing! v0.7-β.9.9 ]</Box> */}


      </section>
    </>
  );
};

export default Hero;