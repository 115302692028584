/* import React, { useState, useRef, useEffect } from "react"; */
/* import { Link } from "react-router-dom"; */
/* import Logo from '../assets/media/Logo.svg'; */
import './Nav.css';
import { useState, useRef, useEffect } from 'react';
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import MenuBut from "./MenuBut";
/* import ToggleButton from "./Toggle"; */

import { Box, Stack, HStack, Center, Text, Heading, Image, useMediaQuery } from "@chakra-ui/react";
let currentScrollPos = 0;

/* const light = { background: "lightGray" };
const dark = { background: "black" }; */

const Nav = (props) => {
  const [noHover] = useMediaQuery('(hover: none)')

  const [navMenuIsActive, setNavMenuIsActive] = useState(false);
  const navMenuIsActiveRef = useRef(navMenuIsActive);
  // Toggle desktop/mobile menu
  const [active, setActive] = useState("nav__menu");
  const [navigationActive, setNavigationActive] = useState("navigation");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
      setNavMenuIsActive(true);
    } else {setActive("nav__menu"); setNavMenuIsActive(false);}
    /* if (navigationActive === "navigation") {
      setNavigationActive("navigation navigation__active");
    } else setNavigationActive("navigation"); */

    /* if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler"); */
  };


  // const headerRef2 = useRef();
  const headerRef = useRef(/* null */);
  
  useEffect(() => {
    /* document.addEventListener("scroll", e => console.log("Scrolling") ); */
    let prevScrollPos = window.scrollY;
    const handleHeaderShowHide = () => {
      //navMenuIsActiveRef.current = navMenuIsActive;
      /* if (navMenuIsActiveRef.current == true) {
        console.log("Don't move menu on scroll !");
      } */
      let currentScrollPos = window.scrollY;
      const headerElement = headerRef.current;
      // const headerElement2 = headerRef2.current;
      if (!headerElement) {
          return;
      }
      if (prevScrollPos >= currentScrollPos) {
        // setTimeout(() => {headerElement.style.transform = "translate(0)"}, 200);
        headerElement.style.transform = "translate(0vh)";
      }
      else if (currentScrollPos <= 0) {
        headerElement.style.transform = "translate(0vh)";
      }
      else if (document.getElementById("menuToggle").classList.contains("toggle")) {
        //console.log("Don't move menu on scroll !");
        headerElement.style.transform = "translate(0vh)";
      }
      else {
        //Insert ternary to account for when "nav__menu nav__active" ?
        headerElement.style.transform = "translateY(-10vh)";
      }
      prevScrollPos = currentScrollPos;
    }

    function isElementInViewport(el) {
      if (!el) return false;
      var rect = el.getBoundingClientRect();
      return (
        rect.top < window.innerHeight && rect.bottom >= 0
      );
    }
    function isElementInTop60px(el) {
      if (!el) return false;
      var rect = el.getBoundingClientRect();
      return (
        /* rect.top >= 0 && rect.top <= 60 */
        rect.top < window.innerHeight && rect.top <= 60 && rect.bottom > 60
      );
    }

    // Set current menu section as active
    let section = document.querySelectorAll(".menuSections");
    let menu = document.querySelectorAll("nav ul li a");
    const projectsSection = document.getElementById("projects-section");
    const menuDisable = () => {
      section.forEach((i) => {
        let top = window.scrollY;
        let offset = i.offsetTop - 50;
        let height = i.offsetHeight;
        let id = i.getAttribute("id");
        if (top >= offset && top < offset + height) {
          menu.forEach((link) => {
            link.classList.remove("nav-active");
            if (id == "home-section") {
            } else {
              document
                .querySelector("nav ul li a[href*=" + id + "]")
                .classList.add("nav-active");
            }
          });
        }

        // Customize the navMenu inside the projects section (white BG)
        /* if (projectsSection) {
          if (isElementInTop60px(projectsSection)) {
            menu.forEach((link) => {
              link.classList.add('invertNavMenuColor');
            });
            //console.log("Projects section is active");
          } else {
            menu.forEach((link) => {
              link.classList.remove('invertNavMenuColor');
            });
          }
        } */


        /* const smallPlane = document.getElementById("smallPlane");
        const homeSection = document.getElementById("home-section");
        // Customize the navMenu inside the projects section (white BG)
        if (homeSection) {
          if (isElementInViewport(homeSection)) {
            gsap.to(smallPlane, {opacity: 1, duration: 3});
            //console.log("smallPlane is visible");
          } else {
            gsap.to(smallPlane, {opacity: 0, duration: 0.1});
          }
        } */

      });
    };
    // document.addEventListener('scroll', handleScroll());

    document.addEventListener('scroll',() => {
    handleHeaderShowHide();
    menuDisable();
    });

    handleHeaderShowHide(); 

    return () => {
      document.removeEventListener('scroll', handleHeaderShowHide)
    }
  }, [props.smoother, navMenuIsActive]);

  const handleMenuClick = (anchor) => (e) => {
    const id = `${anchor}-section`;
    //const element = document.getElementById(id);
    e.preventDefault();

    let element;
    if (id === "home-section") {
      element = null; // Handle scrolling to the top separately
    } else {
      element = document.getElementById(id);
    }

    // const elementpos = element.getBoundingClientRect().top + window.scrollY;
    /* navToggle(); *//* document.getElementById("menuToggle").classList.toggle("toggle"); */
    if (id === "home-section") {
      // Scroll to the top
      noHover
        ? setTimeout(() => props.smoother.current.scrollTo(0, false), 400)
        : gsap.to(props.smoother.current, { scrollTop: 0, duration: 0.5, onComplete: ScrollTrigger.refresh });
    } else if (element) {
      // Scroll to the element
      noHover
      ? setTimeout(() => {/* gsap.to(props.smoother.current, {scrollTop: Math.min(ScrollTrigger.maxScroll(window), props.smoother.current.offset(element)), duration: 0.1,}) */props.smoother.current.scrollTo(element, false)}, 400)
      : gsap.to(props.smoother.current, { scrollTop: Math.min( ScrollTrigger.maxScroll(window), props.smoother.current.offset(element, "top")), duration: 0.5, onComplete: ScrollTrigger.refresh});
      //props.smoother.current.scrollTo(element, true);
      //console.log(props.smoother.current.progress);
      //element.scrollIntoView({behavior: "smooth",block: "start",})
    } else {
      console.error(`Element with id ${id} not found`);
    }
    
    // Dispatch custom event after handleMenuClick logic and consider the "home-section" particular case
    if (id === "home-section") {
      if (document.getElementById("menuToggle").classList.contains("toggle")){
        // If "home-section" is clicked when the menu is open
        window.dispatchEvent(new CustomEvent('handleMenuClickRun', { detail: { anchor } }));
      } else {
        // If "home-section" is clicked when the menu is closed
        return;
      }
    } else {
      window.dispatchEvent(new CustomEvent('handleMenuClickRun', { detail: { anchor } }));
    }
    
  };
  
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  useGSAP(
    () => {


      let matchMedia = gsap.matchMedia();
      matchMedia.add("(hover: none)", () => {
        const openMenuAnim = gsap.timeline({paused:true})
          .set(document.querySelectorAll(".navigation"), {width:"100vw"})
          .fromTo(".nav__menu", {translateX:"100%"}, {translateX:"0%", duration: 0.2, ease: "power2.in"})
          .fromTo(".nav__menu li:nth-of-type(1)", {translateX:"100%"}, {translateX:"0%", duration: 0.2, ease: "power2.in"}, 0.1)
          .fromTo(".nav__menu li:nth-of-type(2)", {translateX:"100%"}, {translateX:"0%", duration: 0.2, ease: "power2.in"}, "<0.1")
          .fromTo(".nav__menu li:nth-of-type(3)", {translateX:"100%"}, {translateX:"0%", duration: 0.2, ease: "power2.in"}, "<0.1")
          .fromTo(".nav__menu li:nth-of-type(4)", {translateX:"100%"}, {translateX:"0%", duration: 0.2, ease: "power2.in"}, "<0.1")

        /* if (navMenuIsActive && window.matchMedia("(hover: none)").matches) {
          openMenuAnim.play();
          //console.log("NavMenu is open");
        } else {}

        if (!navMenuIsActive && window.matchMedia("(hover: none)").matches) {
          openMenuAnim.reverse();
          //console.log("NavMenu is open");
        } */
        // Listen for the custom event
        const onMenuClickRun = (event) => {
          console.log(`Menu clicked for anchor: ${event.detail.anchor}`);
          menuToggled();
        };

        window.addEventListener('handleMenuClickRun', onMenuClickRun);
        
        const menuToggled = () => {
          if (document.getElementById("menuToggle").classList.contains("toggle")){
            openMenuAnim.reverse();
          } else {
            openMenuAnim.play();
          }
          document.getElementById("menuToggle").classList.toggle("toggle");
          //setNavMenuIsActive(prevState => !prevState);
          console.log("menuToggled");
        };
      
        const menuToggleElement = document.getElementById("menuToggle");
        if (menuToggleElement) {
          menuToggleElement.addEventListener("click", menuToggled);
          // Cleanup function to remove the event listener
          return () => {
            menuToggleElement.removeEventListener("click", menuToggled);
          };
        }
      });

  },
    { dependencies: [], revertOnUpdate: true/* , scope: wrapper */ }
  );

  /* useEffect(() => {
    const toggleMenu = () => menuToggled(true);
    const menuToggleElement = document.getElementById("menuToggle");
    menuToggleElement.removeEventListener("click", toggleMenu);
    menuToggleElement.addEventListener("click", toggleMenu);

    return () => menuToggleElement.removeEventListener("click", toggleMenu);
  }, []); */

  return (
    <>
      {/* <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        height="55px"
        sx={{
          '@media (hover: hover)': {
              marginRight: "15px",
          },
        }}
        translateY={0}
        transitionProperty="transform"
        transitionDuration=".3s"
        transitionTimingFunction="ease-in-out"
        backgroundColor="#00000080"
        clipPath="polygon(0 0, 100% 0, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0 90%, 0 0)"
        ref={headerRef2}
        zIndex={998}
      ></Box> */}
      <Box
        id="Nav"
        position="fixed"
        top={0}
        /* left={0} */
        right={0}
        /* width="fit-content" */
        /* sx={{
          '@media (hover: hover)': {
            marginRight: "15px",
          },
        }} */
        translateY={0}
        transitionProperty="transform"
        transitionDuration=".5s"
        transitionTimingFunction="ease-in-out"
        /* boxShadow="dark-lg" */
        ref={headerRef}
        zIndex={900}
      >
        <Stack alignItems="end" >
          <div className={navigationActive}>
            {/* <div className="theme_switch">
              <ToggleButton
                isDark={!isDark}
                invertedIconLogic
                onChange={() => {setDark((prev) => !prev)}}
                onClick={delayToggleColorMode}
              />
            </div> */}
            <div className="logo">
              <a
                href="#home-section"
                onClick={handleMenuClick("home")}
                aria-label="Go to Home Page"
                display="flex"
                flex-direction="row"
                >
                <HStack alignItems="center" >
                  <Image
                    src="./media/64405965.webp"
                    boxSize="20px"
                  />
                  <Text
                    //as="a"
                    className="nav-active underline-from-center"
                    //href="#home-section"
                    //aria-label="Go to Home Page"
                    //onClick={handleMenuClick("home")}
                    //textColor="white"//{props.color}
                    //fontSize="null"
                    //fontFamily='Paytone One'
                    /* sx={{
                      '@media (hover: none)': {
                        '@media screen and (min-width: 768px)': {
                          fontSize: "4rem",
                        }
                      },
                    }} */
                  >
                  André d'Melo
                  </Text>
                </HStack>
              </a>
            </div>
            <div className="navs-placer" />

            <nav /* ref={headerRef} */>
              <ul className={active}>
                {/* <li><Center
                      width="100%"
                      height="100%"
                      >
                    <Text
                      as="a"
                      className="nav-active underline-from-center"
                      href="#home-section"
                      aria-label="Go to Home Page"
                      onClick={handleMenuClick("home")}
                    >
                    Home
                    </Text>
                </Center></li> */}
                <li><Center
                      /* bg={menuSectionBG[0]} */
                      width="100%"
                      height="100%"
                      >
                    <Text
                      as="a"
                      className="underline-from-center"
                      href="#about-section"
                      aria-label="Go to About Page"
                      onClick={handleMenuClick("about")}
                      /* mixBlendMode="difference" */
                      //textColor="white"//{props.color}
                      /* fontSize="null"
                      fontFamily='Paytone One' */
                      /* sx={{
                        '@media (hover: none)': {
                          '@media screen and (min-width: 768px)': {
                            fontSize: "4rem",
                          }
                        },
                      }} */
                    >
                    Experience
                    </Text>
                </Center></li>
                <li><Center
                      /* bg={menuSectionBG[0]} */
                      width="100%"
                      height="100%"
                      >
                    <Text
                      as="a"
                      className="underline-from-center"
                      href="#projects-section"
                      aria-label="Go to Projects Page"
                      onClick={handleMenuClick("projects")}
                      //textColor="white"//{props.color}
                      /* fontSize="null" */
                      /* fontWeight="null"
                      fontFamily='Paytone One' */
                    >
                    Projects
                    </Text>
                </Center></li>
                <li><Center
                      /* bg={menuSectionBG[0]} */
                      width="100%"
                      height="100%"
                      >
                    <Text
                      as="a"
                      className="underline-from-center"
                      href="#contact-section"
                      aria-label="Go to Contact Page"
                      onClick={handleMenuClick("contact")}
                      //textColor="white"//{props.color}
                      /* fontSize="null"
                      fontFamily='Paytone One' */
                    >
                    Contact
                    </Text>
                </Center></li>
                {/* <li><Center
                      bg={menuSectionBG[0]}
                      width="100%"
                      height="100%"
                      >
                </Center></li> */}
                {/*<li><a href="#contact" aria-label="Go to Contact Section" onClick={handleClick("contact")}><Heading as="h1" color={color} fontSize="null">Contact</Heading></a></li>*/}
              </ul>
              <div id="menuToggle" className="nav__toggler"/* onClick={navToggle} */ /* className="menuBut" */ /* className={icon} */>
                <Box className="line1" bg="#fff"></Box>
                <Box className="line2" bg="#fff"></Box>
                <Box className="line3" bg="#fff"></Box>
                {/* <MenuBut/> */}
              </div>
            </nav>

          </div>
        </Stack>
      </Box>
    </>
  );
};

export default Nav;