import React from 'react';
import { useMediaQuery } from "@chakra-ui/react";
import './Loader.css'; // Assuming you will create a CSS file for styling

const Loader = ({ progress }) => {

  /* ===== Media Queries ===== */
  const [noHover] = useMediaQuery('(hover: none)')
  const [portrait] = useMediaQuery('(orientation: portrait)')
  const [under768] = useMediaQuery('(max-width: 767px)')

  const progressInt = parseInt(progress, 10);

  let circleRadius = portrait ? (under768 ? 20 : 22) : 10

  let circleStrokeWidth = portrait ? (under768 ? 6 : 10) : 8

  let circleSize = circleRadius+"vw"
  //console.log(circleSize)

  let strokeDashCalc = (circleRadius * 6.3)
  //console.log(strokeDashCalc)



  return (
    <div className="loader-container">

      {/* <div className="loader">
        <span></span>
        <span></span>
        <span></span>
      </div> */}

      {/* <div className="progress-bar-container"><div className="progress-bar" style={{ width: `${progress}%` }}></div></div> */}
      <div className="progress-container">
        <div className="progress-text">{progressInt} %</div>
        <svg className="progress-circle" width="100%" height="100%">
          <defs>
            <filter id="inset-shadow">
              <feFlood floodColor="#080808"/>
              <feComposite operator="out" in2="SourceAlpha"/>
              <feGaussianBlur stdDeviation="4"/>
              <feComposite operator="in" in2="SourceGraphic"/>
              <feBlend mode="multiply" in="SourceGraphic" />
            </filter>
          </defs>
          <circle
            className="progress-circle-bg"
            cx="50%"
            cy="50%"
            r={circleSize} // Set the radius to 15vw for a circle size of 15vw
            fill="transparent"
            stroke="#272727"
            strokeWidth={circleStrokeWidth}
          ></circle>
          <circle
            className="progress-circle-fill"
            cx="50%"
            cy="50%"
            r={circleSize} // Set the radius to 15vw for a circle size of 15vw
            fill="transparent"
            stroke="#b00000"
            strokeWidth={circleStrokeWidth}
            filter="url(#inset-shadow)"
            strokeDasharray={`${strokeDashCalc}vw`} // 6.3 * 7.5vw = 47.25vw
            strokeDashoffset={`${strokeDashCalc - (strokeDashCalc * progressInt) / 100}vw`} // Adjusted for 30vw size
            //transform="rotate(-90deg 50% 50%)"
          ></circle>
        </svg>
      </div>
    </div>
  );
};

export default Loader;