import "./ManguekyoStudioVideoWallItem.css";

function ManguekyoStudioVideoWallItem(props) {

  return (
    <div id={props.id} className="manguekyoStudioVideoWallItem">

      <div className="manguekyoStudioVideoWallItemVideo">
        {/* <img src={props.imagesrc} alt={props.alt}/> */}
        <video
          id={props.videoId}
          className=""
          src={props.video}
          typeof="video/mp4"
          playsInline
          muted
          autoPlay
          loop
          onLoadedData={() => {}}
        />
      </div>
      {/* <h1>{props.xPos}</h1> */}

    </div>
  );
};

export default ManguekyoStudioVideoWallItem;