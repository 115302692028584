import "./ProjectStack.css";
import { useState, useRef } from 'react';
import {
    Flex,
    Text,
    HStack,
    Box,
    Image,
    Stack,
    VStack,
    StackDivider,
    useMediaQuery,
    Center,
    Link,
    Popover,
    PopoverTrigger,
    Button,
    PopoverContent,
    PopoverHeader,
    PopoverCloseButton,
    PopoverArrow,
    PopoverBody,
    background
} from "@chakra-ui/react";
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

function ProjectStack(props) {

  const project = useRef();

  /* ===== Flash Notice ===== */
  const handleFlashNotice = (flashNotice, id) => {
    if (flashNotice === undefined) {
      /* console.log("undefined"); */
      return null;
    }
    else {
      /* console.log(techStack); */
      return <>
        <Popover key={id} zIndex={500} isLazy closeOnBlur={true} placement='top-start'>
          <PopoverTrigger>
              <Button size={{base: "sm", md: "sm", lg: "md", xl: "md"}} colorScheme="red" bg='#480000' fontSize={{base: "0.95rem", md: "0.95rem", lg: "0.8rem"}} marginTop="5%" color="white">Flash project notice</Button>
          </PopoverTrigger>
          <PopoverContent zIndex="popover" width={{base: "23rem", md: "25rem", lg: "23rem"}} backgroundColor="#480000" borderColor='white' textAlign="center">
              {/* <PopoverHeader fontWeight='semibold'>Popover placement</PopoverHeader> */}
              <PopoverArrow backgroundColor="#480000" borderColor='rgba(200, 0, 0, 0.75)' color="rgba(200, 0, 0, 0.75)"/>
              {/* <PopoverCloseButton /> */}
              <PopoverBody color="white" fontSize={{base: "0.8rem", md: "0.9rem", lg: "0.8rem"}} textAlign="start">
              {flashNotice}
              </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    }
  }

  useGSAP(
    () => {

    },
    { dependencies: [props.color, props.noHover, props.portrait, props.under768], revertOnUpdate: true, scope: project }
  );

  return (
    <div className="projectWrapper">
      <Box className="projectItem" ref={project} /* bg={props.bgColor} */>
        
        <h1>{props.title}</h1>
          <h1>{props.title}</h1>
        <h2>{props.servicesCard}</h2>
          <h2>{props.servicesCard}</h2>
        <h3>{props.year}</h3>
          <h3>{props.year}</h3>
        <Flex className="projectMain flexRow2Col">
          <Flex className="projectDescription flexCol" /* hidden */>
            <Flex className="flexRow2ColDesktopTablet">
              <Box className="projectDescriptionOverview flexCol2row">
                {/* <h4>Project Overview</h4> */}
                <p>{props.description}</p>
                <Flex className="flexCol" paddingTop="20px">
                  <h4>Tech Stack</h4>
                  <Flex className="flexRow" zIndex={10} paddingTop="6px">
                    {props.html && <Image key="html" src={props.html} alt="html logo"/>}
                    {props.css && <Image key="css" src={props.css} alt="css logo"/>}
                    {props.react && <Image key="react" src={props.react} alt="react logo"/>}
                    {props.reactNative && <Image key="reactNative" src={props.reactNative} alt="reactNative logo"/>}
                    {props.next && <Image key="next" src={props.next} alt="next logo"/>}
                    {props.foundation && <Image key="foundation" src={props.foundation} alt="foundation logo"/>}
                    {props.tailwind && <Image key="tailwind" src={props.tailwind} alt="tailwind logo"/>}
                    {props.gsap && <Image key="gsap" src={props.gsap} alt="gsap logo"/>}
                    {props.three && <Image key="three" src={props.three} alt="three logo"/>}
                    {props.flash && <Image key="flash" src={props.flash} alt="flash logo"/>}
                    {props.premiere && <Image key="premiere" src={props.premiere} alt="premiere logo"/>}
                    {props.aftereffects && <Image key="aftereffects" src={props.aftereffects} alt="aftereffects logo"/>}
                    {props.sqlite && <Image key="sqlite" src={props.sqlite} alt="sqlite logo"/>}
                  </Flex>
                </Flex>
                {handleFlashNotice(props.popover, props.id)}
                {/* <h3>Tech Stack</h3>
                <Image src={props.next} width={{ base: "20px", sm: "20px", md: "20px", lg: "20px", xl: "20px" }} alt="next logo"/> */}
              </Box>
              <Flex className="projectDescriptionDetails flexCol2RowDesktopTablet">
                <Flex className="projectDescriptionDetailsSections flexCol">
                  <h4>Services</h4>
                  <p>{props.services}</p>
                </Flex>
                <Flex className="projectDescriptionDetailsSections flexCol">
                  <h4>Year</h4>
                  <p>{props.year}</p>
                </Flex>
                <Flex className="projectDescriptionDetailsSections flexCol">
                  <h4>Client</h4>
                  <p>{props.client}</p>
                </Flex>
              </Flex>
            </Flex>
            <Flex className="projectLink flexCol">
              <Flex className="flexRow">
                {props.github &&
                  <Link className="githubLink" href={props.github} isExternal>
                    <svg className="github" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 96">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" /* fill="#fff" *//>
                    </svg>
                    <h5>Github</h5>
                  </Link>
                }
                <Link className="linkButton" href={props.link} isExternal>
                    <svg className="svgLinkArrowIcon-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
                      <path d="m438.6,169.41c12.5,12.5,12.5,32.79,0,45.28l-160,159.94c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.79,0-45.28l105.5-105.36H32c-17.7,0-32-14.29-32-31.99s14.3-31.99,32-31.99h306.81l-105.4-105.36c-12.5-12.5-12.5-32.79,0-45.28s32.8-12.5,45.3,0l160,159.94-.1.1h0Z" strokeWidth="0"/>
                    </svg>
                    <svg className="svgLinkArrowIcon-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
                      <path d="m438.6,169.41c12.5,12.5,12.5,32.79,0,45.28l-160,159.94c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.79,0-45.28l105.5-105.36H32c-17.7,0-32-14.29-32-31.99s14.3-31.99,32-31.99h306.81l-105.4-105.36c-12.5-12.5-12.5-32.79,0-45.28s32.8-12.5,45.3,0l160,159.94-.1.1h0Z" strokeWidth="0"/>
                    </svg>
                  {/* {props.linktext} */}<Text>Launch Project</Text>
                </Link>
              </Flex>
            </Flex>
          </Flex>

          <Box className="projectMainImage">
            {/* {props.image && <Image key="image" className="projectMainImageBG" id="projectImage" src={props.image} srcSet={props.imageset} alt="Project BG Screenshot"></Image>} */}
            
            {props.bgImage && <Image key="bgImage" className="projectMainImageBG" id="projectImageFG" src={props.bgImage} srcSet={props.bgImageset} alt="Project FG Screenshot"></Image>}

            <Box className="projectMainImageFGWrapper">
              {props.fgImage && <Image key="fgImage" className="projectMainImageFG" id="projectImageFG" src={props.fgImage} srcSet={props.fgImageset} alt="Project FG Screenshot"></Image>}
            </Box>

            <Box className="projectMainVideoWrapper">
              {props.video && <video
                key="video"
                className="projectMainVideo"
                id="projectVideo"
                src={props.video}
                playsInline
                autoPlay
                loop
                muted
              ></video>}
            </Box>
          </Box>
        </Flex>
        <button className="backButton" id="projectBackButton">
          <svg className="svgBackArrowIcon-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
            {/* <path d="m9.38,169.41c-12.5,12.5-12.5,32.79,0,45.28l160,159.94c12.5,12.5,32.8,12.5,45.3,0s12.5-32.79,0-45.28l-105.5-105.36h306.8c17.7,0,32-14.29,32-31.99s-14.3-31.99-32-31.99H109.17l105.4-105.36c12.5-12.5,12.5-32.79,0-45.28s-32.8-12.5-45.3,0L9.27,169.31l.1.1Z" strokeWidth="0"/> */}
          </svg>
          <span className="backButtonText">Close Project</span>
          <svg className="svgBackArrowIcon-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
            <path d="m9.38,169.41c-12.5,12.5-12.5,32.79,0,45.28l160,159.94c12.5,12.5,32.8,12.5,45.3,0s12.5-32.79,0-45.28l-105.5-105.36h306.8c17.7,0,32-14.29,32-31.99s-14.3-31.99-32-31.99H109.17l105.4-105.36c12.5-12.5,12.5-32.79,0-45.28s-32.8-12.5-45.3,0L9.27,169.31l.1.1Z" strokeWidth="0"/>
          </svg>
        </button>
      </Box>
    </div>
    );
};

export default ProjectStack;