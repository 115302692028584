import React, { useEffect, useState } from 'react';
/* import {Routes, Route} from 'react-router-dom' */
import { ChakraProvider } from '@chakra-ui/react'
import { AlertProvider } from "./context/alertContext";

import Loader from './components/Loader';
import Home from './components/Home';

/* import { keepTheme } from './components/themes'; */

import "./App.css";
import "./fonts/stylesheet.css";
import jsonData from './resourcesList.json';


const App = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const resources = jsonData.resources;
  
    const loadResource = (resource) => {
      return new Promise((resolve, reject) => {
        const handleLoad = () => resolve();
        const handleError = () => reject(new Error(`Failed to load resource: ${resource}`));
  
        if (resource.endsWith('.webp') || resource.endsWith('.png') || resource.endsWith('.apng') || resource.endsWith('.svg') || resource.endsWith('.ico') || resource.endsWith('.jpg')) {
          const img = new Image();
          img.onload = handleLoad;
          img.onerror = handleError;
          img.src = resource;
        } else if (resource.endsWith('.mp4')) {
          const video = document.createElement('video');
          video.src = resource;
          video.onload = handleLoad;
          video.onerror = handleError;
          document.head.appendChild(video);
        } else if (resource.endsWith('.css')) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = resource;
          link.onload = handleLoad;
          link.onerror = handleError;
          document.head.appendChild(link);
        } else if (resource.endsWith('.js')) {
          const script = document.createElement('script');
          script.src = resource;
          script.onload = handleLoad;
          script.onerror = handleError;
          document.body.appendChild(script);
        }
      });
    };
  
    const promises = resources.map((resource) => loadResource(resource));
  
    Promise.all(promises)
      .then(() => {
        setLoadingProgress(100); // All resources loaded
      })
      .catch((error) => {
        console.error(error); // Log the error for failed resource loading
      });
  
    promises.forEach((promise, index) => {
      promise.then(() => {
        setLoadingProgress((prevProgress) => prevProgress + (100 / resources.length));
      });
    });
  }, []);
  
  return (
    <>
      {loadingProgress < 100 && <Loader progress={loadingProgress} />}
      {<ChakraProvider>
        <AlertProvider>
          {/* <main> */}
          {/* <div className={`App ${className}`}> */}
            {/* <Header/> */}
            {/* <Nav/> */}
            <Home/>
            {/* <About /> */}
            {/* <Projects />
            <ProjectsArchive />
            <Contact /> */}
          {/* </div> */}
          {/* </main> */}
        </AlertProvider>
      </ChakraProvider>}
    </>
  );
}

export default App;