import React, {/* useEffect, useRef, useState */} from "react";
import "./Contact.css";
import {
  Box,
  Button,
  //FormControl,
  FormErrorMessage,
  //FormLabel,
  //Heading,
  Input,
  //Text,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
//import * as Yup from 'yup';
//import FullScreenSection from "./FullScreenSection";
//import useSubmit from "../hooks/useSubmit";
//import {useAlertContext} from "../context/alertContext";


import { useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { ContactFormSchema } from '../lib/schema.ts'
//import { sendEmail } from './_actions.ts'
import { toast } from 'sonner'


import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GSDevTools } from "gsap/GSDevTools";

export type ContactFormInputs = z.infer<typeof ContactFormSchema>


gsap.registerPlugin(
  useGSAP,
  ScrollTrigger,
  GSDevTools);

const Contact = () => {

    const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isSubmitting }
    } = useForm<ContactFormInputs>({
      resolver: zodResolver(ContactFormSchema)
    })
  
    /* const processForm: SubmitHandler<ContactFormInputs> = async data => {
      ///console.log(data)
      const result = await sendEmail(data)
  
      if (result?.success) {
        console.log({ data: result.data })
        toast.success('Email sent!')
        reset()
        return
      } else {
        // toast error
        console.log(result?.error)
        toast.error('Something went wrong!')
      }
    } */

    const processForm: SubmitHandler<ContactFormInputs> = async data => {
      try {
        const response = await fetch('/.netlify/functions/sendEmailResend', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        const result = await response.json();
  
        if (response.ok) {
          toast.success('Email sent!');
          reset();
          //console.log('Email sent!');
        } else {
          toast.error(result.error || 'Something went wrong!');
          //console.log('Something went wrong!');
        }
      } catch (error) {
        console.error('Catch error: ', error);
        toast.error('Something went wrong!');
      }
    };

  return (
    <section id="contact-section" className="menuSections">
    <VStack
        backgroundColor='#000000' //{props.bgColor}
    >
    <VStack
      /* isDarkBackground */
      minHeight="100svh"
      color='#ffffff' //{props.color}
      /* isDarkBackground={bgColor} */
      justifyContent="center"
      alignItems="center"
      /* py={20} */
      spacing={8}
      zIndex="200"
      /* className="snapSection" */
      width="100%"
      position='relative'
    >
      <Box
        position="absolute"
        bg="transparent"
        width="100%"
        top="-5.01vw"
        zIndex="200"
      >
        <Box
          height="5vw"
          width="100%"
          backgroundColor="black"
          clipPath="polygon(0% 45%, 0% 100%, 100% 100%, 100% 45%, 53% 85%, 50% 95%, 47% 85%)"
          webkit-clip-path="polygon(0% 45%, 0% 100%, 100% 100%, 100% 45%, 53% 85%, 50% 95%, 47% 85%)"
        />
        <Box height="3px" width="100%" marginTop="-1px" backgroundColor="black"/>
      </Box>
      <VStack className="contactWrapper" w="100%">
        <div className="contactHeader" >
          <p className="contactHeading gsap-reveal-p">
            Contact
          </p>
          <p className="contactText gsap-reveal-forms gsap-reveal-t">
            What's on your mind?
          </p>
        </div>
        <Box
          px={6}
          /* paddingTop={{ base: "6", md: "4", lg: "6" }} */
          rounded="md"
          w="100%"
          maxWidth={{ base: "95%", md: "600px", lg: "768px" }}
        >
          <form onSubmit={handleSubmit(processForm)}>
            <VStack id="gsap-reveal-forms" spacing={{ base: "0.65rem", md: "1.5vh", lg: "2vh" }}/* {4} */ paddingBottom={{ base: "0", md: "4", lg: "8" }}>
                <div className="contact-forms contact-forms-control">
                    <label htmlFor="name">Name</label>
                    <Input
                      id="name"
                      className="contact-forms"
                      //name="firstName"
                      borderColor='#ffffff' //{props.color}
                      //{...formik.getFieldProps("firstName")}
                      {...register('name')}
                    />
                   {errors.name?.message && (
                      <p className="error">
                        {errors.name.message}
                      </p>
                    )}
                   {/* <FormErrorMessage>{errors.name?.message}</FormErrorMessage> */}
                </div>
                <div className="contact-forms contact-forms-control">
                    <label htmlFor="email">Email Address</label>
                    <Input
                      id="email"
                      className="contact-forms"
                      //name="email"
                      type="email"
                      borderColor='#ffffff' //{props.color}
                      //{...formik.getFieldProps("email")}
                      {...register('email')}
                    />
                    {errors.email?.message && (
                      <p className="error">
                        {errors.email.message}
                      </p>
                    )}
                    {/* <FormErrorMessage>{errors.email?.message}</FormErrorMessage> */}
                </div>
                <div className="contact-forms contact-forms-control">
                  <label htmlFor="type">Type of enquiry</label>
                  <Select
                    id="type"
                    className="contact-forms"
                    //name="type"
                    borderColor='#ffffff' //{props.color}
                    {...register('messagekind')}
                  >
                    <option value="Freelance Project Proposal">Freelance project proposal</option>
                    <option value="Say Hello">Say hello!</option>
                    {/* <option value="openSource">Open source consultancy session</option> */}
                    <option value="Other">Other</option>
                  </Select>
                </div>
                <div className="contact-forms contact-forms-control">
                  <label htmlFor="message">Your message</label>
                  <Textarea
                    id="message"
                    className="contact-forms"
                    //name="comment"
                    height={{ base: "5rem", md: "10rem", lg: "150" }}/* {125} */
                    borderColor='#ffffff' //{props.color}
                    //{...formik.getFieldProps("comment")}
                    {...register('message')}
                  />
                  {errors.message?.message && (
                    <p className="error">{errors.message.message}</p>
                  )}
                </div>
                <div>
                  <Button
                    className="contact-forms"
                    type="submit"
                    colorScheme="whatsapp"
                    width="full"
                    disabled={isSubmitting}
                  >
                   {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
            </VStack>
          </form>

        </Box>
      </VStack>
    </VStack>
    </VStack>
    </section>
  );
};

export default Contact;