import React from "react";
import { useState, useRef, useEffect } from 'react';
//import { useDeviceOrientation } from './useDeviceOrientation';
import "./Projects.css";
import { Box, VStack, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import ProjectStack from "./ProjectStack";
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { GSDevTools } from "gsap/GSDevTools";

gsap.registerPlugin(
  useGSAP, 
  ScrollTrigger,
  CustomEase,
  GSDevTools);

const htmlLogo = "../media/logos/html5-original-wordmark-white.svg"
const cssLogo = "../media/logos/css3-original-wordmark-white.svg"
const tailwindLogo = "../media/logos/tailwind-css.svg"
const react = "../media/logos/react-original-wordmark.webp"
const reactNative = "../media/logos/reactNative.webp"
const nextLogo = "../media/logos/next-js-full-white.svg"
const gsapLogo = "../media/logos/GSAP+Windmill-white.svg"
const threeLogo = "../media/logos/three.js-white.svg"
const flashLogo = "../media/logos/adobe-flash-v3.svg"
const foundationLogo = "../media/logos/foundation-original-wordmark-white.webp"
const premiereLogo = "../media/logos/premierepro.svg"
const aftereffectsLogo = "../media/logos/aftereffects.svg"
const sqliteLogo = "../media/logos/sqlite-white.webp"



const Projects = (props) => {
  const [mmappProjectVideo, setMmappProjectVideo] = useState("../media/works/mmapp/mmapp.640w.mp4");
  const [ryeProjectVideo, setRyeProjectVideo] = useState("../media/works/ryeBakeryApp/RyeBakeryApp.660w.mp4");
  const [littleLemonProjectVideo, setLittleLemonProjectVideo] = useState("../media/works/littleLemon/littleLemon.640w.mp4");
  const [sWC2015Video, setSWC2015Video] = useState("../media/works/manguekyoStudio/SWC2015/SWC2015.768w.mp4");
  const [manguekyoVideo, setManguekyoVideo] = useState("../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio.640w.mp4");
  const [uchihaVideo, setUchihaVideo] = useState("../media/works/manguekyoStudio/uchiha/UchihaItachi.854w.mp4");
  const [aparkVideo, setAparkVideo] = useState("../media/works/manguekyoStudio/aPark/aParkServicosDVD/apServicos.640w.mp4");
  const [shodoVideo, setShodoVideo] = useState("../media/works/manguekyoStudio/shodo/shodo.768w.mp4");

  useEffect(() => {
    let mmappProjectVideo = "../media/works/mmapp/mmapp.1920w.mp4";
    if (props.under768) {
      mmappProjectVideo = "../media/works/mmapp/mmapp.768w.mp4";
    } else if (props.isUnder1024) {
      mmappProjectVideo = "../media/works/mmapp/mmapp.1024w.mp4";
    } else if (props.isUnder1280) {
      mmappProjectVideo = "../media/works/mmapp/mmapp.1280w.mp4";
    } else if (props.isUnder1536) {
      mmappProjectVideo = "../media/works/mmapp/mmapp.1920w.mp4";
    }
    setMmappProjectVideo(mmappProjectVideo);

    let ryeProjectVideo = "../media/works/ryeBakeryApp/RyeBakeryApp.660w.mp4";
    if (props.under768) {
      ryeProjectVideo = "../media/works/ryeBakeryApp/RyeBakeryApp.330w.mp4";
    } else if (props.isUnder1024) {
      ryeProjectVideo = "../media/works/ryeBakeryApp/RyeBakeryApp.440w.mp4";
    } else if (props.isUnder1280) {
      ryeProjectVideo = "../media/works/ryeBakeryApp/RyeBakeryApp.440w.mp4";
    } else if (props.isUnder1536) {
      ryeProjectVideo = "../media/works/ryeBakeryApp/RyeBakeryApp.660w.mp4";
    }
    setRyeProjectVideo(ryeProjectVideo);

    let littleLemonProjectVideo = "../media/works/littleLemon/littleLemon.1280w.mp4";
    if (props.under768) {
      littleLemonProjectVideo = "../media/works/littleLemon/littleLemon.768w.mp4";
    } else if (props.isUnder1024) {
      littleLemonProjectVideo = "../media/works/littleLemon/littleLemon.1024w.mp4";
    } else if (props.isUnder1280) {
      littleLemonProjectVideo = "../media/works/littleLemon/littleLemon.1280w.mp4";
    } else if (props.isUnder1536) {
      littleLemonProjectVideo = "../media/works/littleLemon/littleLemon.1280w.mp4";
    }
    setLittleLemonProjectVideo(littleLemonProjectVideo);

    let sWC2015Video = "../media/works/manguekyoStudio/SWC2015/SWC2015.1280w.mp4";
    if (props.under768) {
      sWC2015Video = "../media/works/manguekyoStudio/SWC2015/SWC2015.768w.mp4";
    } else if (props.isUnder1024) {
      sWC2015Video = "../media/works/manguekyoStudio/SWC2015/SWC2015.1024w.mp4";
    } else if (props.isUnder1280) {
      sWC2015Video = "../media/works/manguekyoStudio/SWC2015/SWC2015.1280w.mp4";
    } else if (props.isUnder1536) {
      sWC2015Video = "../media/works/manguekyoStudio/SWC2015/SWC2015.1920w.mp4";
    }
    setSWC2015Video(sWC2015Video);

    let manguekyoVideo = "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio.1280w.mp4";
    if (props.under768) {
      manguekyoVideo = "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio.768w.mp4";
    } else if (props.isUnder1024) {
      manguekyoVideo = "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio.1024w.mp4";
    } else if (props.isUnder1280) {
      manguekyoVideo = "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio.1280w.mp4";
    } else if (props.isUnder1536) {
      manguekyoVideo = "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio.1280w.mp4";
    }
    setManguekyoVideo(manguekyoVideo);

    let uchihaVideo = "../media/works/manguekyoStudio/uchiha/UchihaItachi.1920w.mp4";
    if (props.under768) {
      uchihaVideo = "../media/works/manguekyoStudio/uchiha/UchihaItachi.768w.mp4";
    } else if (props.isUnder1024) {
      uchihaVideo = "../media/works/manguekyoStudio/uchiha/UchihaItachi.1024w.mp4";
    } else if (props.isUnder1280) {
      uchihaVideo = "../media/works/manguekyoStudio/uchiha/UchihaItachi.1280w.mp4";
    } else if (props.isUnder1536) {
      uchihaVideo = "../media/works/manguekyoStudio/uchiha/UchihaItachi.1920w.mp4";
    }
    setUchihaVideo(uchihaVideo);

    let aparkVideo = "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apServicos.1536w.mp4";
    if (props.under768) {
      aparkVideo = "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apServicos.768w.mp4";
    } else if (props.isUnder1024) {
      aparkVideo = "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apServicos.1024w.mp4";
    } else if (props.isUnder1280) {
      aparkVideo = "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apServicos.1280w.mp4";
    } else if (props.isUnder1536) {
      aparkVideo = "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apServicos.1536w.mp4";
    }
    setAparkVideo(aparkVideo);

    let shodoVideo = "../media/works/manguekyoStudio/shodo/shodo.1920w.mp4";
    if (props.under768) {
      shodoVideo = "../media/works/manguekyoStudio/shodo/shodo.768w.mp4";
    } else if (props.isUnder1024) {
      shodoVideo = "../media/works/manguekyoStudio/shodo/shodo.1024w.mp4";
    } else if (props.isUnder1280) {
      shodoVideo = "../media/works/manguekyoStudio/shodo/shodo.1280w.mp4";
    } else if (props.isUnder1536) {
      shodoVideo = "../media/works/manguekyoStudio/shodo/shodo.1920w.mp4";
    }
    setShodoVideo(shodoVideo);


  }, [props.under768, props.isUnder1024, props.isUnder1280, props.isUnder1536]);

  const projectsList = [
    {
      id: "MMAPP",
      title: "MMAPP",
      servicesCard: "Web Development, 3D Motion Graphics",
      services: ["Web Development",<br/>,"Web Design",<br/>,"3D Motion Graphics",<br/>,"Animations"],
      description: ["Landing website for MMAPP Tech's innovative platform, featuring a comprehensive suite of applications designed specifically for the Mixed Martial Arts (MMA) sport.",
      <br/>, <br/>,
      "Featuring a sleek layout with animations and 3D motion graphics showcasing MMAPP's main features and the many benefits it brings to Federations, their athletes, coaches, clubs and promoters.",/* 
      <br/>, <br/>,
      "Built using Next.JS, GSAP, React Three Fiber (R3F) and Tailwind.", */],
      bgImage: "../media/works/mmapp/mmapp-3D-bg.webp",
      bgImageset: "../media/works/mmapp/mmapp-3D-bg-480w.webp 480w, ../media/works/mmapp/mmapp-3D-bg-768w.webp 768w, ../media/works/mmapp/mmapp-3D-bg-1024w.webp 1024w, ../media/works/mmapp/mmapp-3D-bg-1280w.webp 1280w, ../media/works/mmapp/mmapp-3D-bg.webp 3920w",
      fgImage: "../media/works/mmapp/mmapp-3D-fg.webp",
      fgImageset: "../media/works/mmapp/mmapp-3D-fg-480w.webp 480w, ../media/works/mmapp/mmapp-3D-fg-768w.webp 768w, ../media/works/mmapp/mmapp-3D-fg-1024w.webp 1024w, ../media/works/mmapp/mmapp-3D-fg-1280w.webp 1280w, ../media/works/mmapp/mmapp-3D-fg.webp 3920w",
      video: mmappProjectVideo,
      year: "2024",
      client: "MMAPP Tech",
      icon: "../media/works/mmapp/icon-512.png",
      next: nextLogo,
      three: threeLogo,
      gsap: gsapLogo,
      tailwind: tailwindLogo,
      github: "https://github.com/andredmelo/mmapptech.com",
      link: "https://www.mmapptech.com",
      linktext: "mmapptech.com",
    },
    {
      id: "RyeBakeryApp",
      title: "Rye Bakery",
      servicesCard: "App Development & Design",
      services: ["App Development",<br/>,"App Design"],
      description: ["An App was developed for this Bali based bakery (fictional) so that their clients could easily login, browse the current menu and subscribe to E-mail notifications on special offers and newsletters.",
      <br/>, <br/>,
      "Built using React Native on Expo Go with AsyncStorage for the users preferences and SQLite Database to populate, query and filter the menu items."],
      bgImage: "../media/works/ryeBakeryApp/RyeBakeryAPP-bg.webp",
      bgImageset: "../media/works/ryeBakeryApp/RyeBakeryAPP-bg-480w.webp 480w, ../media/works/ryeBakeryApp/RyeBakeryAPP-bg-768w.webp 768w, ../media/works/ryeBakeryApp/RyeBakeryAPP-bg-1024w.webp 1024w, ../media/works/ryeBakeryApp/RyeBakeryAPP-bg-1280w.webp 1280w, ../media/works/ryeBakeryApp/RyeBakeryAPP-bg.webp 2580w",
      fgImage: "../media/works/ryeBakeryApp/RyeBakeryAPP-fg.webp",
      fgImageset: "../media/works/ryeBakeryApp/RyeBakeryAPP-fg-480w.webp 480w, ../media/works/ryeBakeryApp/RyeBakeryAPP-fg-768w.webp 768w, ../media/works/ryeBakeryApp/RyeBakeryAPP-fg-1024w.webp 1024w, ../media/works/ryeBakeryApp/RyeBakeryAPP-fg-1280w.webp 1280w, ../media/works/ryeBakeryApp/RyeBakeryAPP-fg.webp 2580w",
      video: ryeProjectVideo,
      year: "2023",
      client: "Rye Bakery (fict.)",
      icon: "../media/works/ryeBakeryApp/RyeBakeryIcon.webp",
      html: htmlLogo,
      css: cssLogo,
      reactNative: reactNative,
      sqlite: sqliteLogo,
      github: "https://github.com/andredmelo/rye-bakery",
      link: "https://snack.expo.dev/@andre1melo/rye-bakery?platform=ios",
      linktext: "snack.expo.dev/Rye-Bakery",
    },
    {
      id: "littleLemonWebsite",
      title: "Little Lemon",
      servicesCard: "Web Development",
      services: "Web Development",
      description: ["A Website with table reservation and online order for a family owned Mediterranean restaurant (fictional), focused on traditional recipes served with a modern twist.",
      <br/>, <br/>,
      /* "Built using React, HTML and CSS.",
      <br/>, */
      "In the table reservation page JSON data of the available booking times is fetched from the API. After filling out the (client-side validated) form the data is submitted to the API to process the booking."],
      /* image: "../media/works/little_lemon_website/Little_Lemon_Website_1s.webp",
      imageset: "../media/works/little_lemon_website/Little_Lemon_Website_1s-480px.webp 480w, ../media/works/little_lemon_website/Little_Lemon_Website_1s-768px.webp 768w, ../media/works/little_lemon_website/Little_Lemon_Website_1s-992px.webp 992w", */
      bgImage: "../media/works/littleLemon/littleLemon-bg.webp",
      bgImageset: "../media/works/littleLemon/littleLemon-bg-480w.webp 480w, ../media/works/littleLemon/littleLemon-bg-768w.webp 768w, ../media/works/littleLemon/littleLemon-bg-1024w.webp 1024w, ../media/works/littleLemon/littleLemon-bg-1280w.webp 1280w, ../media/works/littleLemon/littleLemon-bg.webp 3205w",
      fgImage: "../media/works/littleLemon/littleLemon-fg.webp",
      fgImageset: "../media/works/littleLemon/littleLemon-fg-480w.webp 480w, ../media/works/littleLemon/littleLemon-fg-768w.webp 768w, ../media/works/littleLemon/littleLemon-fg-1024w.webp 1024w, ../media/works/littleLemon/littleLemon-fg-1280w.webp 1280w, ../media/works/littleLemon/littleLemon-fg.webp 3205w",
      video: littleLemonProjectVideo,
      year: "2023",
      client: "Little Lemon (fict.)",
      icon: "../media/works/littleLemon/LittleLemonIcon.webp",
      html: htmlLogo,
      css: cssLogo,
      react: react,
      link: "https://little-lemon-andredmelo.netlify.app",
      linktext: "little-lemon-andreDmelo",
    },
  ];



  const projectsArchiveList = [
    /* {
      id: "swc2015",
      title: "SWC 2015",
      servicesCard: "Video Editing",
      services: "Video Editing",
      description: ["A quick video edit I made for my sport (Streetboard) community.",
      <br/>,
      "Non-stop edit of the Streetboard World Championships 2015 at the D.O.W. in Dudelange, Luxembourg."],
      bgImage: "../media/works/manguekyoStudio/SWC2015/SWC2015-bg.webp",
      bgImageset: "../media/works/manguekyoStudio/SWC2015/SWC2015-bg-480w.webp 480w, ../media/works/manguekyoStudio/SWC2015/SWC2015-bg-768w.webp 768w, ../media/works/manguekyoStudio/SWC2015/SWC2015-bg-1024w.webp 1024w, ../media/works/manguekyoStudio/SWC2015/SWC2015-bg-1280w.webp 1280w, ../media/works/manguekyoStudio/SWC2015/SWC2015-bg.webp 3268w",
      fgImage: "../media/works/manguekyoStudio/SWC2015/SWC2015-fg.webp",
      fgImageset: "../media/works/manguekyoStudio/SWC2015/SWC2015-fg-480w.webp 480w, ../media/works/manguekyoStudio/SWC2015/SWC2015-fg-768w.webp 768w, ../media/works/manguekyoStudio/SWC2015/SWC2015-fg-1024w.webp 1024w, ../media/works/manguekyoStudio/SWC2015/SWC2015-fg-1280w.webp 1280w, ../media/works/manguekyoStudio/SWC2015/SWC2015-fg.webp 3268w",
      video: sWC2015Video,
      year: "2015",
      client: "Streetboard community",
      icon: "../media/manguekyo-studio/SWC-2015-icon.webp",
      premiere: premiereLogo,
      aftereffects: aftereffectsLogo,
      link: "https://vimeo.com/manage/videos/134969223",
      linktext: "vimeo.com/SWC-2015",
    }, */
    {
      id: "manguekyostudio",
      title: "Manguekyo Studio", //ŠŠŚŜṠｓ
      servicesCard: "Concept, Web Design, Web Development",
      services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
      description: ["This was Manguekyo Studio's website until 2013.",
      <br/>,
      "A responsive design (pretty new in 2012) HTML5 and CSS3 on top of a Foundation framework solution.",
      <br/>,
      "Some of the inner projects are not available.",
      <br/>, <br/>,
      "Flash projects might not play correctly on some devices"],
      /* image: "../media/manguekyo-studio/ms.webp",
      imageset: "../media/manguekyo-studio/ms-480px.webp 480w, ../media/manguekyo-studio/ms-768px.webp 768w, ../media/manguekyo-studio/ms-992px.webp 992w, ../media/manguekyo-studio/ms-1280px.webp 1280w", */
      bgImage: "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-bg.webp",
      bgImageset: "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-bg-480w.webp 480w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-bg-768w.webp 768w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-bg-1024w.webp 1024w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-bg-1280w.webp 1280w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-bg.webp 2225w",
      fgImage: "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-fg.webp",
      fgImageset: "../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-fg-480w.webp 480w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-fg-768w.webp 768w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-fg-1024w.webp 1024w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-fg-1280w.webp 1280w, ../media/works/manguekyoStudio/manguekyoStudio/manguekyoStudio-fg.webp 2225w",
      video: manguekyoVideo,
      year: "2012",
      client: "Self",
      icon: "../media/manguekyo-studio/ms-icon.webp",
      html: htmlLogo,
      css: cssLogo,
      foundation: foundationLogo,
      link: "https://manguekyo-studio.netlify.app/",
      linktext: "manguekyo-studio",
    },
    {
      id: "uchiha",
      title: "Uchiha Itachi",
      servicesCard: "Concept, Web Design, Web Development",
      services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
      description: ["A Flash solution was created for a fansite of the Uchiha Clan that engages the visitor in a cinematic and interactive depiction of the origins and life of the fictional character Uchiha Itachi from the popular anime series Naruto®."],
      popover:  ["Flash projects might not play correctly on some devices.",
      <br/>,
      "Sound (an integral part of the experience) might be disabled by default in your system.",
      <br/>,
      "Navigation was not designed for touch devices so it can be challenging or quite impossible"],
      /* image: "../media/manguekyo-studio/uchiha-v2.webp",
      imageset: "../media/manguekyo-studio/uchiha-v2-480px.webp 480w, ../media/manguekyo-studio/uchiha-v2-768px.webp 768w, ../media/manguekyo-studio/uchiha-v2-992px.webp 992w", */
      bgImage: "../media/works/manguekyoStudio/uchiha/uchiha-bg.webp",
      bgImageset: "../media/works/manguekyoStudio/uchiha/uchiha-bg-480w.webp 480w, ../media/works/manguekyoStudio/uchiha/uchiha-bg-768w.webp 768w, ../media/works/manguekyoStudio/uchiha/uchiha-bg-1024w.webp 1024w, ../media/works/manguekyoStudio/uchiha/uchiha-bg-1280w.webp 1280w, ../media/works/manguekyoStudio/uchiha/uchiha-bg.webp 1663w",
      fgImage: "../media/works/manguekyoStudio/uchiha/uchiha-fg.webp",
      fgImageset: "../media/works/manguekyoStudio/uchiha/uchiha-fg-480w.webp 480w, ../media/works/manguekyoStudio/uchiha/uchiha-fg-768w.webp 768w, ../media/works/manguekyoStudio/uchiha/uchiha-fg-1024w.webp 1024w, ../media/works/manguekyoStudio/uchiha/uchiha-fg-1280w.webp 1280w, ../media/works/manguekyoStudio/uchiha/uchiha-fg.webp 1663w",
      video: uchihaVideo,
      year: "2006",
      client: "Uchiha Clan fansite",
      icon: "../media/manguekyo-studio/uchiha_crest.webp",
      html: htmlLogo,
      css: cssLogo,
      flash: flashLogo,
      link: "https://manguekyo-studio.netlify.app/2006/uchiha/",
      linktext: "manguekyo-studio/uchiha",
    },
    /* {
      id: "AcademiaPatinsAgarra",
      title: "Agarra a Vida",
      services: "Web Design, Web Development",
      description: ["A|Park created a sports show that travels throughout the schools of Portugal.",
      <br/>, <br/>,
      "A simple Flash solution was developed for students, teachers and fans to obtain information of dates, visited schools and athletes present in the show.",<br/>,<br/>],
      popover:  ["Flash projects might not play correctly on some devices."],
      image: "../media/manguekyo-studio/aParkServicosDVD/apservicos.webp",
      imageset: "../media/manguekyo-studio/agarra-a-vida-480px.webp 480w, ../media/manguekyo-studio/agarra-a-vida-768px.webp 768w, ../media/manguekyo-studio/agarra-a-vida-992px.webp 992w, ../media/manguekyo-studio/agarra-a-vida-1280px.webp 1280w",
      year: "2005",
      client: "A|Park",
      icon: "../media/manguekyo-studio/agarra-a-vida-icon.webp",
      html: htmlLogo,
      css: cssLogo,
      flash: flashLogo,
      link: "https://manguekyo-studio.netlify.app/2005/tour_agarra_a_vida/index.html",
      linktext: "manguekyo-studio/agarra-a-vida",
    }, */
    {
      id: "AcademiaPatinsServices",
      title: "A|Park",
      servicesCard: "Concept, Design, DVD Development",
      services: ["Concept",<br/>,"Design",<br/>,"DVD Development"],
      description: ["In order to promote and increase their sales of skateparks and other services, A|Park (formerly Academia dos Patins) needed an interactive DVD that would transmit the high-energy and professionalism of the company and it's premium quality skatepark solutions.",
      <br/>,<br/>,
      "(Videos and slideshows are not available.)"],
      popover:  ["Flash projects might not play correctly on some devices."],
      /* image: "../media/manguekyo-studio/aParkServicosDVD/apservicos.webp",
      imageset: "../media/manguekyo-studio/aParkServicosDVD/apservicos-480px.webp 480w, ../media/manguekyo-studio/aParkServicosDVD/apservicos-768px.webp 768w, ../media/manguekyo-studio/aParkServicosDVD/apservicos-992px.webp 992w", */
      bgImage: "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-bg.webp",
      bgImageset: "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-bg-480w.webp 480w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-bg-768w.webp 768w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-bg-1024w.webp 1024w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-bg-1280w.webp 1280w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-bg.webp 1536w",
      fgImage: "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-fg.webp",
      fgImageset: "../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-fg-480w.webp 480w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-fg-768w.webp 768w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-fg-1024w.webp 1024w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-fg-1280w.webp 1280w, ../media/works/manguekyoStudio/aPark/aParkServicosDVD/apservicos-fg.webp 1536w",
      video: aparkVideo,
      year: "2004",
      client: "A|Park",
      icon: "../media/manguekyo-studio/aParkServicosDVD/apservicos_icon.webp",
      flash: flashLogo,
      link: "https://manguekyo-studio.netlify.app/2004/academia_dos_patins_dvd/dvd",
      linktext: "manguekyo-studio/apark-dvd",
    },
    {
      id: "shodo",
      title: "Shodo",
      servicesCard: "Concept, Web Design, Web Development",
      services: ["Concept",<br/>,"Web Design",<br/>,"Web Development"],
      description: ["A project on Shodo (Japanese caligraphy art) about its history, techniques, materials and related subjects.",
      <br/>,
      "A Flash solution was developed with a bilingual Japanese and Portuguese navigational menu."],
      popover:  ["Flash projects might not play correctly on some devices.",
      <br/>,
      "Sound (an integral part of the experience) might be disabled by default in your system.",
      <br/>,
      "Navigation was not designed for touch devices so it can be challenging or impossible"],
      /* image: "../media/manguekyo-studio/shodo2s.webp",
      imageset: "../media/manguekyo-studio/shodo2s-480px.webp 480w, ../media/manguekyo-studio/shodo2s-768px.webp 768w, ../media/manguekyo-studio/shodo2s-992px.webp 992w", */
      bgImage: "../media/works/manguekyoStudio/shodo/shodo-bg.webp",
      bgImageset: "../media/works/manguekyoStudio/shodo/shodo-bg-480w.webp 480w, ../media/works/manguekyoStudio/shodo/shodo-bg-768w.webp 768w, ../media/works/manguekyoStudio/shodo/shodo-bg-1024w.webp 1024w, ../media/works/manguekyoStudio/shodo/shodo-bg-1280w.webp 1280w, ../media/works/manguekyoStudio/shodo/shodo-bg.webp 1600w",
      fgImage: "../media/works/manguekyoStudio/shodo/shodo-fg.webp",
      fgImageset: "../media/works/manguekyoStudio/shodo/shodo-fg-480w.webp 480w, ../media/works/manguekyoStudio/shodo/shodo-fg-768w.webp 768w, ../media/works/manguekyoStudio/shodo/shodo-fg-1024w.webp 1024w, ../media/works/manguekyoStudio/shodo/shodo-fg-1280w.webp 1280w, ../media/works/manguekyoStudio/shodo/shodo-fg.webp 1600w",
      video: shodoVideo,
      year: "2003",
      client: "etic_",
      icon: "../media/manguekyo-studio/sho.webp",
      html: htmlLogo,
      css: cssLogo,
      flash: flashLogo,
      link: "https://manguekyo-studio.netlify.app/2003/sho/",
      linktext: "manguekyo-studio/shodo",
    },
  ];

  const projects = useRef();

  const [archiveVisible, setArchiveVisible] = useState(false);

  useGSAP(
    () => {
      //let projectsArchiveButtons = gsap.utils.toArray(".projectsArchiveButton");
      let projectsArchive = document.getElementById("projectsArchive");
      let projectsArchiveVeil = document.getElementById("projectsArchiveVeil");
      let projectsArchiveButton = document.getElementById("projectsArchiveButton");
      let projectsArchiveButtonSvg1 = document.querySelector(".projectsArchiveButtosvgLinkArrowIcon-1");
      let projectsArchiveButtonSvg2 = document.querySelector(".projectsArchiveButtosvgLinkArrowIcon-2");
      let projectsArchiveButtonText = document.getElementById(".projectsArchiveButtonText");
      //console.log(button.children);
      gsap.set(projectsArchiveButton, {textDecoration: "none"});
      gsap.set(projectsArchiveButtonSvg1, {rotation:90, transformOrigin:"50% 50%"})
      gsap.set(projectsArchiveButtonSvg2, {rotation:90, transformOrigin:"50% 50%"})
      gsap.set(projectsArchiveButtonSvg2, {translateY:"-400%"})

      const projectsArchiveButtonHover2Expand = gsap.timeline({paused:true})
        .to(projectsArchiveButton, {color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(174, 1, 0, 1)", boxShadow: "0 1px 5px 0 rgba(200, 0, 0, 1), inset 1px 1px 3px rgba(150, 0, 0, 1), inset -1px -1px 3px rgba(150, 0, 0, 1)", duration: 0.1, ease:"power1.in"}, 0)
        .fromTo(projectsArchiveButtonSvg1, {translateY:"0%"}, {fill: "rgba(255, 255, 255, 1)", translateY:"400%", duration: 0.2, ease:"power1.out"}, 0)
        .fromTo(projectsArchiveButtonSvg2, {translateY:"-400%"}, {fill: "rgba(255, 255, 255, 1)", translateY:"0%", duration: 0.2, ease:"power1.out"}, 0)
        .to(projectsArchiveButton.children[2], {translateX:"0.5%", duration: 0.2, ease:"power1.out"}, 0)

      const projectsArchiveButtonHover2Collapse = gsap.timeline({paused:true})
        .to(projectsArchiveButton, {color: "rgba(255, 255, 255, 1)", backgroundColor: "rgba(174, 1, 0, 1)", boxShadow: "0 1px 5px 0 rgba(200, 0, 0, 1), inset 1px 1px 3px rgba(150, 0, 0, 1), inset -1px -1px 3px rgba(150, 0, 0, 1)", duration: 0.1, ease:"power1.in"}, 0)
        .fromTo(projectsArchiveButtonSvg1, {translateY:"400%"}, {fill: "rgba(255, 255, 255, 1)", translateY:"0%", duration: 0.2, ease:"power1.out"}, 0)
        .fromTo(projectsArchiveButtonSvg2, {translateY:"0%"}, {fill: "rgba(255, 255, 255, 1)", translateY:"-400%", duration: 0.2, ease:"power1.out"}, 0)
        .to(projectsArchiveButton.children[2], {translateX:"0.5%", duration: 0.2, ease:"power1.out"}, 0)

      const projectsArchiveButtonDown = gsap.timeline({paused:true})
        .to(projectsArchiveButton, {backgroundColor: "rgba(200, 0, 0, 1)", boxShadow: "0px 1px 5px 0px rgba(200, 0, 0, 1), inset 4px 4px 12px rgba(96, 0, 0, 1), inset -4px -4px 12px rgba(96, 0, 0, 1)", translateY:"2%", duration: 0.125, ease:"power1.in"}, 0)
        //.to(projectsArchiveButtonSvg2, {translateY:"35%", duration: 0.125, ease:"power1.in"}, 0)

      const expandCollapseProjectsArchive = () => {
        if (archiveVisible) { // CollapseProjectsArchive
          projectsArchiveButton.removeEventListener("click", expandCollapseProjectsArchive);
          //gsap.fromTo(projectsArchive, {height: "120%", opacity: 1}, {height: "0px", opacity: 0, duration: 0.5, ease:"power1.inOut"});
          projectsArchive.classList.remove("expanded");
          gsap.fromTo(projectsArchiveVeil, {opacity: 1}, {opacity: 0, duration: 0.5, ease:"power1.inOut", onComplete: () => {window.dispatchEvent(new Event('projectsArchiveToggled'));}});
          setArchiveVisible(false);
          gsap.to(projectsArchiveButtonSvg1, {rotation:90})
          gsap.to(projectsArchiveButtonSvg2, {rotation:90})
          // Dispatch custom event
          //window.dispatchEvent(new Event('projectsArchiveToggled'));


          //console.log("collapse");
          //console.log(archiveVisible);
        }
        if (!archiveVisible) { // ExpandProjectsArchive
          projectsArchiveButton.removeEventListener("click", expandCollapseProjectsArchive);
          //gsap.fromTo(projectsArchive, {height: "0px", opacity: 0}, {height: "120%", opacity: 1, duration: 0.5, ease:"power1.inOut"});
          projectsArchive.classList.add("expanded");
          gsap.fromTo(projectsArchiveVeil, {opacity: 0}, {opacity: 1, duration: 0.125, ease:"power1.inOut", onComplete: () => {window.dispatchEvent(new Event('projectsArchiveToggled'));}});
          setArchiveVisible(true);
          gsap.to(projectsArchiveButtonSvg1, {rotation:270})
          gsap.to(projectsArchiveButtonSvg2, {rotation:270})
          // Dispatch custom event
          //window.dispatchEvent(new Event('projectsArchiveToggled'));

          //console.log("expand");
          //console.log(archiveVisible);
        }
      }
      projectsArchiveButton.addEventListener("mouseenter", () => {
        if (!archiveVisible) {projectsArchiveButtonHover2Expand.play()}
        else {projectsArchiveButtonHover2Collapse.play()}});
      projectsArchiveButton.addEventListener("mouseleave", () => {
        if (!archiveVisible) {projectsArchiveButtonHover2Expand.reverse()}
        else {projectsArchiveButtonHover2Collapse.reverse()}});
      projectsArchiveButton.addEventListener("mousedown", () => projectsArchiveButtonDown.play());
      projectsArchiveButton.addEventListener("mouseup", () => projectsArchiveButtonDown.reverse());
      projectsArchiveButton.addEventListener("click", expandCollapseProjectsArchive);
    },
    { dependencies: [archiveVisible, setArchiveVisible], revertOnUpdate: true, scope: projects }
  );


  useGSAP(
    () => {

      let backButtons = gsap.utils.toArray(".backButton");
      backButtons.forEach((button, index) => {
        //console.log(button.children);
        const backButtonHover = gsap.timeline({paused:true})
          .to(button, {width: "8.75rem", backgroundColor: "rgba(200, 0, 0, 0.85)", boxShadow: "0px 1px 5px 0px rgba(200, 0, 0, 1), inset 1px 1px 3px rgba(150, 0, 0, 1), inset -1px -1px 3px rgba(150, 0, 0, 1)", duration: 0.2, ease:"power1.out"}, 0)
          .to(button.children[0], {translateX:"-3.5rem", duration: 0.2, ease:"power1.inOut"}, 0)
          .to(button.children[1], {translateX:"-0.6rem", opacity:1, duration: 0.2, ease:"power1.inOut"}, 0)
          .to(button.children[2], {translateX:"-3.5rem", duration: 0.2, ease:"power1.inOut"}, 0)

        const backButtonDown = gsap.timeline({paused:true})
        .to(button, {backgroundColor: "rgba(200, 0, 0, 0.65)", boxShadow: "0px 1px 5px 0px rgba(200, 0, 0, 1), inset 4px 4px 12px rgba(96, 0, 0, 1), inset -4px -4px 12px rgba(96, 0, 0, 1)", translateY:"2%", duration: 0.125, ease:"power1.in"}, 0)
        .to(button.children[1], {translateX:"-0.875rem", translateY:"1%", duration: 0.2, ease:"power1.inOut"}, 0)
        .to(button.children[2], {translateX:"-3.75rem", translateY:"1%", duration: 0.2, ease:"power1.inOut"}, 0)

        button.addEventListener("mouseenter", () => backButtonHover.play());
        button.addEventListener("mouseleave", () => backButtonHover.reverse());
        button.addEventListener("mousedown", () => backButtonDown.play());
        button.addEventListener("mouseup", () => backButtonDown.reverse());
      });

      let linkButtons = gsap.utils.toArray(".linkButton");
      linkButtons.forEach((button, index) => {
        //console.log(button.children);
        gsap.set(button, {textDecoration: "none"});
        gsap.set(button.querySelectorAll(".svgLinkArrowIcon-1, .svgLinkArrowIcon-2"), {rotation:-45, transformOrigin:"50% 50%"})
        gsap.set(button.querySelectorAll(".svgLinkArrowIcon-2"), {translateX:"-300%", translateY:"300%"})
        const linkButtonHover = gsap.timeline({paused:true})
          .to(button, {backgroundColor: "rgba(200, 0, 0, 0.85)", boxShadow: "0 1px 5px 0 rgba(200, 0, 0, 1), inset 1px 1px 3px rgba(150, 0, 0, 1), inset -1px -1px 3px rgba(150, 0, 0, 1)", duration: 0.1, ease:"power1.in"}, 0)
          .to(button.querySelector(".svgLinkArrowIcon-1"), {translateX:"330%", translateY:"-300%", duration: 0.2, ease:"power1.out"}, 0)
          .to(button.querySelector(".svgLinkArrowIcon-2"), {translateX:"30%", translateY:"0%", duration: 0.2, ease:"power1.out"}, 0)
          //.to(button.children[0], {translateX:"350%", duration: 0.2, ease:"power1.out"}, 0)
          //.to(button.children[1], {translateX:"320%", duration: 0.2, ease:"power1.out"}, 0)
          .to(button.children[2], {translateX:"1%", duration: 0.2, ease:"power1.out"}, 0)
        
        const linkButtonDown = gsap.timeline({paused:true})
        .to(button, {backgroundColor: "rgba(200, 0, 0, 0.65)", boxShadow: "0px 1px 5px 0px rgba(200, 0, 0, 1), inset 4px 4px 12px rgba(96, 0, 0, 1), inset -4px -4px 12px rgba(96, 0, 0, 1)", /* border:"1px solid white", */ translateY:"2%", duration: 0.125, ease:"power1.in"}, 0)
        .to(button.querySelector(".svgLinkArrowIcon-2"), {translateX:"60%", translateY:"-30%", duration: 0.2, ease:"power1.out"}, 0)

        button.addEventListener("mouseenter", () => linkButtonHover.play());
        button.addEventListener("mouseleave", () => linkButtonHover.reverse());
        button.addEventListener("mousedown", () => linkButtonDown.play());
        button.addEventListener("mouseup", () => linkButtonDown.reverse());
      });


      let projectItems = gsap.utils.toArray(".projectWrapper");

      projectItems.forEach((project, index) => {
        /* ========== Initial set's ========== */
        gsap.set(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {autoAlpha: 0})
        gsap.set(project.querySelectorAll('.projectItem h2:nth-of-type(1)'), {autoAlpha: 0})
        /* const hideProperty = (target) => { target.hidden = true; console.log("hide"); }
        const showProperty = (target) => { target.hidden = false; console.log("show"); } */
        //console.log(project);
        
        //const boundingBox = project.querySelector('.projectDescription').getBoundingClientRect();
        //console.log(project.querySelector('.projectDescription').getBoundingClientRect().top);

        /* ========== open Project Landscape ========== */
        const openProjectLandscape = gsap.timeline({
          paused:true,
          onStart: ()=>{
            projectMouseEventListeners(false);
            resetStyles();
            gsap.set(project, {cursor: "default"});
            /* showProperty(project.children[0].children[6].children[0]) */
          },
          onUpdate: (self)=>{props.smoother.current.scrollTo(project, true, "top "+((window.innerHeight - ((window.innerHeight*100)/100)) / 2)+"px")},
          onComplete: ()=>{
            project.classList.add(".active");
            projectMouseEventListeners(false);
            resetStyles();
            projectOpenMouseEventListeners(false);
            projectCloseMouseEventListeners(true);
          },
          onReverseComplete: ()=>{
            project.classList.remove(".active");
            projectMouseEventListeners(true);
            projectCloseMouseEventListeners(false);
            projectOpenMouseEventListeners(true);
            gsap.set(project, {cursor: "pointer"});
          }
          })
          .set(project.querySelectorAll('.projectMain'), {z: 0, overflow: "hidden"}) //projectMain Z reset
          .set(project.querySelector('.projectMainImageFG'), {z: 0}) //projectMainImageFG Z reset
          .to(project.querySelectorAll('.projectItem'), {width: "100vw", height: "100vh", margin: "0 0 12vh 0", duration: 0.25, ease: "slow(0.7,0.7,false)"}, 0)
          .to(project.querySelectorAll('.projectItem'), {outline: "0.25rem solid #480000", duration: 0.25})
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h2'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h3'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .fromTo(project.querySelectorAll('.projectItem h1:nth-of-type(2)'),
          {/* fontSize: "6vw", */ position: "absolute", maxWidth:"100%", translateX:"50%", left:"50%", xPercent:-100, textAlign:"start", },
          {fontSize: "5vw", position: "absolute", maxWidth:"32%", translateX:"0%", left:"0vw", xPercent:0, paddingLeft:"3%", duration: 0.45, ease:"power1.out"}, 0.25)
          .to(project.querySelectorAll('.projectItem h1'), {translateY: "30vh", yPercent:-100, duration: 0.25, ease:"power2.in"}, 0.35)
          /* .to(project.querySelectorAll('.projectMainImage'),
            {maxWidth:"60%", height: "fit-content", maxHeight: "80%", ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0) */
          .to(project.querySelectorAll('.projectMainImageBG'),
          {maxHeight: "80vh", padding: "0% 2.5% 0% 0%", translateY: "50vh", yPercent:-50, ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .to(project.querySelectorAll('.projectMainImageFG'),
          {maxHeight: "80vh", padding: "0% 2.5% 0% 0%", translateY: "50vh", yPercent:-50, ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .to(project.querySelectorAll('.projectMainVideoWrapper'),
          {padding: "0% 3% 0% 0%", ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          /* .to(project.querySelectorAll('.projectMainImage'),
            {padding: "0% 2.5% 0% 0%", maxWidth:"60%", height: "fit-content", maxHeight: "80%", translateY: "50vh", yPercent:-50, ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0) */
          //.fromTo(project.querySelectorAll('.projectMainImage'),{objectFit:"cover"}, {objectFit:"contain"}, 0.4)
          .fromTo(project.querySelectorAll('.projectMainImageBG'),{objectFit:"cover"}, {objectFit:"contain"}, 0.4)
          .fromTo(project.querySelectorAll('.projectMainImageFG'),{objectFit:"cover"}, {objectFit:"contain"}, 0.4)
          //.fromTo(project.querySelectorAll('.projectMainVideo'),{objectFit:"cover"}, {objectFit:"contain"}, 0.4)
          .fromTo(project.querySelectorAll('.projectDescription'),
            {flexBasis:"0%", padding: "0%", opacity: 0},
            {flexBasis:"55%", padding: "3%", translateY: "60vh", yPercent:-50, opacity: 1, duration: 0.5, ease:"power2.in",
              /* onComplete: function(){
                let projectDescriptionTop = project.querySelectorAll('.projectDescription').getBoundingClientRect().top;
              } */}, 0)
          .fromTo(project.querySelectorAll('.backButton'), {autoAlpha:0, left:"52%"}, {autoAlpha:1, left:"50%", duration: 0.25, ease:"power2.out"}, 0.35)
          .fromTo(project.querySelectorAll('.projectMainImageBG'),{opacity:1, filter:"brightness(1) blur(0px)"}, {opacity:0, filter:"brightness(0) blur(50px)", duration: 0.35, ease:"power2.in"}, 0.2)
          .fromTo(project.querySelectorAll('.projectMainImageFG'),{opacity:1, filter:"brightness(1) blur(0px)"}, {opacity:0, filter:"brightness(0) blur(50px)", duration: 0.35, ease:"power2.in"}, 0.2)
          /* .set(project.querySelector('.projectMainVideo'), {currentTime: 0, pause: true}, 0) */
          .fromTo(project.querySelectorAll('.projectMainVideo'),{opacity:0, filter:"brightness(0) blur(25px)"}, {opacity:1, filter:"brightness(1) blur(0px)", duration: 0.5, ease:"power1.out"}, 0.5)
          .set(project.querySelector('.projectMainVideo'), {currentTime: 0}, 0.5)
          /* .set(project.querySelector('.projectMainVideo'), {play: true}, 1.25) */


        /* ========== open Project Portrait Mobile ========== */
        const openProjectPortraitMobile = gsap.timeline({
          paused:true,
          onStart: ()=>{
            projectMouseEventListeners(false);
            resetStyles();
            gsap.set(project, {cursor: "default"});
            // showProperty(project.children[0].children[6].children[0])
          },
          onUpdate: (self)=>{
            props.smoother.current.scrollTo(project, true, "top "+((window.innerHeight - ((window.innerHeight*100)/100)) / 2)+"px");
          },
          onComplete: ()=>{
            project.classList.add(".active");
            projectMouseEventListeners(false);
            resetStyles();
            projectOpenMouseEventListeners(false);
            projectCloseMouseEventListeners(true);
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          },
          onReverseComplete: ()=>{
            project.classList.remove(".active");
            projectMouseEventListeners(true);
            projectCloseMouseEventListeners(false);
            projectOpenMouseEventListeners(true);
            gsap.set(project, {cursor: "pointer"});
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          }
          })
          .set(project.querySelectorAll('.projectMain'), {z: 0}) //projectMain Z reset
          .set(project.querySelector('.projectMainImageFG'), {z: 0}) //projectMainImageFG Z reset
          //.set(project.querySelector('.projectItem'), {transformPerspective: 0})
          .to(project.querySelectorAll('.projectItem'), {width: "100vw", height: "fit-content", minHeight: "100lvh", margin: "0 0 40vw 0", duration: 0.25, ease: "slow(0.7,0.7,false)"}, 0)
          .fromTo(project.querySelectorAll('.projectDescription'),
            {flexBasis:"0%", padding: "0%", opacity: 0},
            {flex: "1 1 auto", padding: "7rem 5% 5% 5%", /* translateY: "60vh", yPercent:-50, */ opacity: 1, duration: 0.5, ease:"power2.in"}, 0)
          /* .fromTo(project.querySelectorAll('.projectMainImage'),{objectFit:"cover"}, 
            {flex: "1", width: "auto", height: "fit-content", maxHeight: "50%", objectFit:"contain", ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0) */
          .to(project.querySelectorAll('.projectMainImageBG'),
              {padding: "3% 3% 3% 3%", height: "1lvh", /* objectFit:"contain", */ ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .to(project.querySelectorAll('.projectMainImageFG'),
              {padding: "3% 3% 3% 3%", height: "1lvh", /* objectFit:"contain", */ ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .to(project.querySelectorAll('.projectMainVideo'),
              {/* outline: "0.5rem solid green", */ /* maxHeight: "fit-content", */ /* objectFit:"scale-down",  */ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h2'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h3'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .fromTo(project.querySelectorAll('.projectItem'), {outline: "0rem solid #480000"}, {outline: "0.25rem solid #480000", duration: 0.25})
          .fromTo(project.querySelectorAll('.projectItem h1:nth-of-type(2)'),
          {/* fontSize: "6vw", */ position: "absolute", /* width:"max-content", */ left:"50%", xPercent:-100, textAlign:"start", paddingTop: "0px", paddingBottom: "0px"},
          {fontSize: "3.75rem", position: "relative", translateY: "4rem", /* width:"100%", */ textAlign:"center", paddingTop: "30px", paddingBottom: "10px",/* yPercent:-100, */ duration: 0.25, ease:"power2.in"}, 0.25)
          //.set(project.querySelectorAll('.projectDescription'),{flexFlow: "column wrap", alignContent: "flex-end"})
            .to(project.querySelectorAll('.projectDescription h4'),{fontSize: "4vw", paddingBottom: "1%", ease:"power2.out"},0.25)
            .to(project.querySelectorAll('.projectDescription p'),{fontSize: "3.5vw", ease:"power2.out"},0.25)
          .fromTo(project.querySelectorAll('.backButton'), {autoAlpha:0, left:"52%"}, {autoAlpha:1, left:"50%", duration: 0.25, ease:"power2.in"}, 0.5)
          .fromTo(project.querySelectorAll('.projectMainImageBG'),{opacity:1, filter:"brightness(1) blur(0px)"}, {opacity:0, filter:"brightness(0) blur(50px)", duration: 0.35, ease:"power2.in"}, 0.2)
          .fromTo(project.querySelectorAll('.projectMainImageFG'),{opacity:1, filter:"brightness(1) blur(0px)"}, {opacity:0, filter:"brightness(0) blur(50px)", duration: 0.35, ease:"power2.in"}, 0.2)
          /* .set(project.querySelector('.projectMainVideo'), {currentTime: 0, pause: true}, 0) */
          .fromTo(project.querySelectorAll('.projectMainVideo'),{opacity:0, filter:"brightness(0) blur(25px)"}, {opacity:1, filter:"brightness(1) blur(0px)", duration: 0.5, ease:"power1.out"}, 0.5)
          .set(project.querySelector('.projectMainVideo'), {currentTime: 0}, 0.5)
          /* .set(project.querySelector('.projectMainVideo'), {play: true}, 1.25) */

        /* ========== open Project Portrait Tablet ========== */
        const openProjectPortraitTablet = gsap.timeline({
          paused:true,
          onStart: ()=>{
            projectMouseEventListeners(false);
            resetStyles();
            gsap.set(project, {cursor: "default"});
            /* showProperty(project.children[0].children[6].children[0]) */
          },
          onUpdate: (self)=>{props.smoother.current.scrollTo(project, true, "top "+((window.innerHeight - ((window.innerHeight*100)/100)) / 2)+"px")},
          onComplete: ()=>{
            project.classList.add(".active");
            projectMouseEventListeners(false);
            resetStyles();
            projectOpenMouseEventListeners(false);
            projectCloseMouseEventListeners(true);
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          },
          onReverseComplete: ()=>{
            project.classList.remove(".active");
            projectMouseEventListeners(true);
            projectCloseMouseEventListeners(false);
            projectOpenMouseEventListeners(true);
            gsap.set(project, {cursor: "pointer"});
            setTimeout(() => {ScrollTrigger.refresh()}, 50);
          }
          })
          .set(project.querySelectorAll('.projectMain'), {z: 0}) //projectMain Z reset
          .set(project.querySelector('.projectMainImageFG'), {z: 0}) //projectMainImageFG Z reset
          //.set(project.querySelector('.projectItem'), {transformPerspective: 0})
          .to(project.querySelectorAll('.projectItem'), {width: "100vw", height: "fit-content", minHeight: "100lvh", margin: "0 0 20vw 0", duration: 0.25, ease: "slow(0.7,0.7,false)"}, 0)
          .fromTo(project.querySelectorAll('.projectItem'), {outline: "0rem solid #480000"}, {outline: "0.25rem solid #480000", duration: 0.25})
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h2'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .to(project.querySelectorAll('.projectItem h3'), {opacity: 0, duration: 0.125}, 0)  // Hide
          .fromTo(project.querySelectorAll('.projectItem h1:nth-of-type(2)'),
          {/* fontSize: "6vw", */ position: "absolute", /* width:"max-content", */ left:"50%", xPercent:-100, textAlign:"start", paddingTop: "0px", paddingBottom: "0px"},
          {fontSize: "14vw", position: "relative", translateY: "4rem", /* width:"100%", */ textAlign:"center", paddingTop: "30px", paddingBottom: "10px", /* yPercent:-100, */ duration: 0.25, ease:"power2.in"}, 0.25)
          /* .fromTo(project.querySelectorAll('.projectMainImage'),{objectFit:"cover"}, 
            {flex: "1", width: "auto", height: "fit-content", objectFit:"contain", ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0) */
          .to(project.querySelectorAll('.projectMainImageBG'),
            {padding: "2.25% 2.25% 2.25% 2.25%", height: "1lvh", /* objectFit:"contain", */ ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          .to(project.querySelectorAll('.projectMainImageFG'),
            {padding: "2.25% 2.25% 2.25% 2.25%", height: "1lvh", /* objectFit:"contain", */ ease: CustomEase.create("custom", "M0,0 C0,0 0.436,-0.097 0.53,-0.099 0.566,-0.1 0.61,-0.091 0.635,-0.08 0.661,-0.068 0.698,-0.034 0.722,-0.01 0.749,0.016 0.778,0.085 0.801,0.126 0.829,0.173 0.863,0.285 0.886,0.345 0.911,0.41 0.931,0.551 0.952,0.626 0.977,0.715 1,1 1,1 ")}, 0)
          /* .to(project.querySelectorAll('.projectMainVideo'),
            {outline: "0.5rem solid green", }, 0) */
          .fromTo(project.querySelectorAll('.projectDescription'),
            {flexBasis:"0%", padding: "0%", opacity: 0},
            {flex: "1 1 auto", padding: "10rem 5% 5% 5%%", /* translateY: "60vh", yPercent:-50, */ opacity: 1, duration: 0.5, ease:"power2.in"}, 0)
          .to(project.querySelectorAll('.projectDescription h4'),{fontSize: "2.2vw", paddingBottom: "1%", ease:"power2.out"},0.25)
          .to(project.querySelectorAll('.projectDescription p'),{fontSize: "1.95vw", ease:"power2.out"},0.25)
          .fromTo(project.querySelectorAll('.backButton'), {autoAlpha:0, left:"52%"}, {autoAlpha:1, left:"50%", duration: 0.25, ease:"power2.in"}, 0.5)
          .fromTo(project.querySelectorAll('.projectMainImageBG'),{opacity:1, filter:"brightness(1) blur(0px)"}, {opacity:0, filter:"brightness(0) blur(50px)", duration: 0.35, ease:"power2.in"}, 0.2)
          .fromTo(project.querySelectorAll('.projectMainImageFG'),{opacity:1, filter:"brightness(1) blur(0px)"}, {opacity:0, filter:"brightness(0) blur(50px)", duration: 0.35, ease:"power2.in"}, 0.2)
          /* .set(project.querySelector('.projectMainVideo'), {currentTime: 0, pause: true}, 0) */
          .fromTo(project.querySelectorAll('.projectMainVideo'),{opacity:0, filter:"brightness(0) blur(25px)"}, {opacity:1, filter:"brightness(1) blur(0px)", duration: 0.5, ease:"power1.out"}, 0.5)
          .set(project.querySelector('.projectMainVideo'), {currentTime: 0}, 0.5)
          /* .set(project.querySelector('.projectMainVideo'), {play: true}, 1.25) */

        /* project.addEventListener("click", () => {
          if (openProject.totalProgress() === 1){
            openProject.reverse();
            // hideProperty(project.children[0].children[6].children[0]);
          } else if (openProject.totalProgress() === 0){
            openProject.play();
          }
        }) */

        const playProject = () => {
          if (window.matchMedia("(orientation: portrait)").matches){
            if (window.matchMedia("(max-width: 767px)").matches){
              //console.log("portraitMobile");
              openProjectPortraitMobile.play()
            } else {
              //console.log("portraitTablet");
              openProjectPortraitTablet.play()
            }
          } else if (window.matchMedia("(orientation: landscape)").matches){
            //console.log("landscape");
            openProjectLandscape.play()
          }
        }
        const reverseProject = () => {
          if (window.matchMedia("(orientation: portrait)").matches){
            if (window.matchMedia("(max-width: 767px)").matches){
              //console.log("portraitMobile");
              openProjectPortraitMobile.reverse()
            } else {
              //console.log("portraitTablet");
              openProjectPortraitTablet.reverse()
            }
          } else if (window.matchMedia("(orientation: landscape)").matches){
            //console.log("landscape");
            openProjectLandscape.reverse()
          }
        }
        //const reverseProject = () => {openProjectLandscape.reverse()}

        const projectOpenMouseEventListeners = (active) => {
          if (active === false) {
            //console.log("Inactive");
            project.removeEventListener("click", playProject);
          } else if (active === true) {
            //console.log("Active");
            project.addEventListener("click", playProject);
          }
        }
        projectOpenMouseEventListeners(true);

        const projectCloseMouseEventListeners = (active, e) => {
          if (active === false) {
            project.querySelector('.backButton').removeEventListener("click", reverseProject);
          } else if (active === true) {
            project.querySelector('.backButton').addEventListener("click", reverseProject);
          }
        }
        projectCloseMouseEventListeners(false);

        /* if (project) {
          openProject.totalProgress() === 1
            ? openProject.reverse() && project.classList.remove(".active") || projectMouseEventListeners(true)
            : null;
        } else if (project) {
          openProject.totalProgress() === 0
            ? openProject.play() && project.classList.add(".active") || projectMouseEventListeners(false) || resetStyles()
            : null;
        } */

        /* ===== Desktop 3D mouse follow on each projects ===== */
        const motionMatchMedia = window.matchMedia("(prefers-reduced-motion)");
        const noHoverMatchMedia = window.matchMedia("(hover: none)");
        //const project = document.querySelector(".projectItem");
        const THRESHOLD = 15;
        const THRESHOLDFG = 10;

        const hoverProjectAnim = gsap.timeline({paused:true})
          .set(project.querySelectorAll('.projectItem h1:nth-of-type(1)'), {autoAlpha: 1})
          .set(project.querySelectorAll('.projectItem h2:nth-of-type(1)'), {autoAlpha: 1})
          //.to(project.querySelectorAll('.projectItem h1:nth-of-type(2)'), {z: 80, ease:"power4.in", duration:0.2})
          //.to(project.querySelectorAll('.projectItem h2:nth-of-type(2)'), {z: 20, duration:0.2}, 0.1)
          //.to(project.querySelectorAll('.projectItem h3:nth-of-type(2)'), {z: 20, duration:0.2}, 0.1)
          .to(project.querySelectorAll('.projectItem h1:nth-of-type(2)'), {z: 25, ease:"power4.in", duration:0.2})
          .to(project.querySelectorAll('.projectItem h2:nth-of-type(2)'), {z: 10, duration:0.2}, 0.1)
          .to(project.querySelectorAll('.projectItem h3:nth-of-type(2)'), {z: 10, duration:0.2}, 0.1)
          //.to(project.querySelector('.projectMainImageBG'), {z: 1, duration:0.1}, 0.2)
          .to(project.querySelector('.projectMainImageFG'), {z: 80, duration:0.1}, 0)
          /* .to(project.children[0].children[3].children[1].children[1], {z: 40, duration:0.2}, "-=0.1")
          .to(project.children[0].children[6], {z: 30, duration:0.2}, "-=0.1") */

        function handleHover(e) {
          const { clientX, clientY, currentTarget } = e;
          const { clientWidth, clientHeight, offsetLeft, offsetTop } = currentTarget;
          const projectItem = currentTarget;
          const projectMainImageFGWrapper = currentTarget.querySelector('.projectMainImageFGWrapper');

          // Calculate the offsets relative to the viewport
          const offsetLeftRelativeToWindow = projectItem.getBoundingClientRect().left;
          const offsetTopRelativeToWindow = projectItem.getBoundingClientRect().top;
          //let offsetTopRelativeToWindow = project.querySelector('.projectItem').getBoundingClientRect().top /* + window.scrollY */;

          const horizontal = (clientX - offsetLeftRelativeToWindow) / clientWidth;
          const vertical = (clientY - offsetTopRelativeToWindow) / clientHeight;
          const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
          const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);
          const rotateXFG = (THRESHOLDFG / 2 - horizontal * THRESHOLDFG).toFixed(2);
          const rotateYFG = (vertical * THRESHOLDFG - THRESHOLDFG / 2).toFixed(2);

          projectItem.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
          projectMainImageFGWrapper.style.transform = `perspective(${clientWidth}px) rotateX(${(rotateYFG / 1.4)}deg) rotateY(${(rotateXFG / 1.4)}deg) scale3d(1, 1, 1)`;
        
          //project.querySelector('.projectItem').style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
          //project.querySelector('.projectMainImageFGWrapper').style.transform = `perspective(${clientWidth}px) rotateX(${(rotateYFG/1.4)}deg) rotateY(${(rotateXFG/1.4)}deg) scale3d(1, 1, 1)`;
          //project.querySelector('.projectMainImageFG').style.transform = `rotateX(${rotateY}deg) rotateY(${rotateY}deg) scale3d(1, 1, 1)`;
          //project.children[0].children[1].style.transform = `perspective(${clientWidth}px) scale3d(1, 1, 1)`;
          hoverProjectAnim.play();
        }

        function resetStyles(e) {
          project.querySelector('.projectItem').style.transform = `rotateX(0deg) rotateY(0deg)`;
          project.querySelector('.projectMainImageFGWrapper').style.transform = `rotateX(0deg) rotateY(0deg)`;
            /* `perspective(${e.currentTarget.clientWidth}px) rotateX(0deg) rotateY(0deg)`; */
          hoverProjectAnim.reverse();
        }

        const projectMouseEventListeners = (active) => {
          if (/* !motionMatchMedia.matches && !noHoverMatchMedia.matches && */ active === false) {
            project.querySelector('.projectItem').removeEventListener("mousemove", handleHover);
            project.querySelector('.projectItem').removeEventListener("mouseleave", resetStyles);
          } else if (noHoverMatchMedia.matches) {
            project.querySelector('.projectItem').removeEventListener("mousemove", handleHover);
            project.querySelector('.projectItem').removeEventListener("mouseleave", resetStyles);
            resetStyles();
          } else if (!motionMatchMedia.matches && !noHoverMatchMedia.matches && active === true) {
            project.querySelector('.projectItem').addEventListener("mousemove", handleHover);
            project.querySelector('.projectItem').addEventListener("mouseleave", resetStyles);
          }
        }
        projectMouseEventListeners(true);


        /* ===== Mobile 3D scroll follow on each projects ===== */
        /* let matchMedia = gsap.matchMedia();
        matchMedia.add("(hover: none)", () => {
          // this setup code only runs when its a touch device with no Hover function
          //console.log("mobile")
          const mobile3DProjectsInAnim = gsap.timeline({
            scrollTrigger: {
              trigger: project,
              start: 'top bottom',
              end: 'top 70%',
              scrub: true,
              //preventOverlaps:true,
              markers:false,
              invalidateOnRefresh:true,
              //fastScrollEnd: 3000,
              //toggleActions: "play none reverse none",
              //onUpdate:(self)=> {console.log("progress:", self.progress)},
            }
          })
            .set(project.querySelector('.projectItem'), {transformOrigin:"50% 0%"}) // , transformPerspective: 600
            .fromTo(project.querySelector('.projectItem'), {scale: 0.5}, {scale: 1, ease:"power1.in"}, 0)
            .fromTo(project.querySelector('.projectMainImage'), {scale: 1.75}, {scale: 1, 
              ease:"power2.in"}, 0)

          const mobile3DProjectsOutAnim = gsap.timeline({
            scrollTrigger: {
              trigger: project,
              start: 'bottom 15%',
              end: 'bottom top',
              scrub: true,
              //preventOverlaps:true,
              markers:false,
              invalidateOnRefresh:true,
              //fastScrollEnd: 3000,
              //toggleActions: "play none reverse none",
              onUpdate:(self)=> {//console.log("progress:", self.progress)
              },
              onEnter:(progress) => {
                //console.log(progress);
                //openProjectPortraitTablet.timeScale(-3);
                //openProjectPortraitTablet.reverse();
              }
            }
          })
            .set(project.querySelector('.projectItem'), {transformOrigin:"50% 100%"})
            .fromTo(project.querySelector('.projectItem'), {scale: 1}, {scale: 0.5, ease:"power1.out"}, 0)

          return () => { // optional
            // custom cleanup code here (runs when it STOPS matching)
            //console.log("desktop")
          };
        }); */

      })

      /* ===== Rejected 3D mouse follow on all projects ===== */
      /* gsap.set(".projectItem", {transformPerspective:"800"})

      let rotateXTo = gsap.quickSetter(".active", "rotateX", "deg"),
          rotateYTo = gsap.quickSetter(".active", "rotateY", "deg");

      window.addEventListener("mousemove", e => {
        let mouseX = e.clientX - window.innerWidth / 2;
        let mouseY = e.clientY - window.innerHeight / 2;
        let rotateValueX = gsap.utils.mapRange(-window.innerWidth / 2, window.innerWidth / 2, -10, 10, mouseX);
        let rotateValueY = gsap.utils.mapRange(-window.innerHeight / 2, window.innerHeight / 2, -10, 10, mouseY);
        let rotateValueXFinal = rotateValueX*2;
        let rotateValueYFinal = rotateValueY*-1.5;
        
        console.log('rotateValueX: ' + rotateValueXFinal + 'rotateValueY: ' + rotateValueYFinal);
        rotateXTo(rotateValueYFinal);
        rotateYTo(rotateValueXFinal);
      }); */

/* GSDevTools.create({animation: openProject}); */
    },
    { dependencies: [props.color, props.noHover, props.portrait, props.under768, setArchiveVisible, archiveVisible], revertOnUpdate: true, scope: projects }
  );


  useGSAP(
    () => {

      let projectItems = gsap.utils.toArray(".projectWrapper");

      projectItems.forEach((projectItem) => {

        const projectItemsAnimIn = gsap.timeline({ paused: true })
        //.set(".projectWrapper .projectItem", {autoAlpha:0})
        .from(projectItem.querySelector(".projectItem"), {
          autoAlpha:0,
          y: "50vh",
          duration: 0.5,
          ease: "power1.out",
        });

          ScrollTrigger.create({
            trigger: projectItem,
            start: 'top 80%',
            scrub: false,
            markers: false,
            //once:true,
            animation: projectItemsAnimIn,
            toggleActions: 'play none none reverse',
          })

      });
    },
    { dependencies: [], revertOnUpdate: true, scope: projects }
  );

  return (
    <section id="projects-section" className="menuSections snapSection" ref={projects}>
      <VStack
          /* backgroundColor={props.bgColor}"rgb(96, 0, 0, 1)" */
          backgroundColor="#000000"
          /* backgroundColor="rgb(255, 255, 255, 1)" */
          /* color={props.color}"white" */
          /* paddingTop="3%" */
          alignItems="center"
          spacing={0}
          zIndex="100"
          width="100%"
          marginTop="-2px"
          /* height="fit-content" */
      >
        <VStack
          id="projects-section-bg"
          backgroundColor="transparent"
          /* backgroundColor="rgb(250, 255, 255, 0.925)" */
          width="100%"
          zIndex="102"
          /* marginBottom="4vh" */
          minHeight="100vh"
          /* color={props.color} */
          /* backdropFilter="blur(5px)" */
          /* marginTop="5rem" */
          /* boxShadow="0rem -0.5em 0.25rem 0.5rem rgb(96, 0, 0, 0.85)" */
        >
          <div className="projectsHeader">
            {/* <div >
              <p aria-label="Projects Heading" className="projects-heading">
                <span data-text="P">P</span>
                <span data-text="r">r</span>
                <span data-text="o">o</span>
                <span data-text="j">j</span>
                <span data-text="e">e</span>
                <span data-text="c">c</span>
                <span data-text="t">t</span>
                <span data-text="s">s</span>
              </p>
            </div> */}

            <h4 className="projectsHeading gsap-reveal-p">
              Projects
            </h4>
          </div>

          {/* <Box marginBottom="0.75rem" className="hover-tap-text"><h2>{props.hoverORtapPJS}</h2></Box> */}

          <Box className="flexProjectsContainer">
            {projectsList.map ((project) => (
              <ProjectStack
                /* key={index} */
                smoother={props.smoother}
                key={project.title}
                id={project.id}
                title={project.title}
                year={project.year}
                client={project.client}
                description={project.description}
                servicesCard={project.servicesCard}
                services={project.services}
                popover={project.popover}
                image={project.image}
                imageset={project.imageset}
                bgImage={project.bgImage}
                bgImageset={project.bgImageset}
                fgImage={project.fgImage}
                fgImageset={project.fgImageset}
                video={project.video}
                icon={project.icon}
                html={project.html}
                css={project.css}
                react={project.react}
                reactNative={project.reactNative}
                next={project.next}
                gsap={project.gsap}
                tailwind={project.tailwind}
                three={project.three}
                foundation={project.foundation}
                /* flash={project.flash}
                premiere={project.premiere} */
                aftereffects={project.aftereffects}
                sqlite={project.sqlite}
                github={project.github}
                link={project.link}
                linktext={project.linktext}
                bgColor={props.bgColor}
                color={props.color}
              />
            ))}
          </Box>


          {<Box className="flexProjectsContainer" width="100vw" zIndex="101">
            <VStack width="100vw">
              <button id="projectsArchiveButton" className="projectsArchiveButton">
                <svg className="projectsArchiveButtosvgLinkArrowIcon-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
                  <path d="m438.6,169.41c12.5,12.5,12.5,32.79,0,45.28l-160,159.94c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.79,0-45.28l105.5-105.36H32c-17.7,0-32-14.29-32-31.99s14.3-31.99,32-31.99h306.81l-105.4-105.36c-12.5-12.5-12.5-32.79,0-45.28s32.8-12.5,45.3,0l160,159.94-.1.1h0Z" strokeWidth="0"/>
                </svg>
                <svg className="projectsArchiveButtosvgLinkArrowIcon-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 384">
                  <path d="m438.6,169.41c12.5,12.5,12.5,32.79,0,45.28l-160,159.94c-12.5,12.5-32.8,12.5-45.3,0s-12.5-32.79,0-45.28l105.5-105.36H32c-17.7,0-32-14.29-32-31.99s14.3-31.99,32-31.99h306.81l-105.4-105.36c-12.5-12.5-12.5-32.79,0-45.28s32.8-12.5,45.3,0l160,159.94-.1.1h0Z" strokeWidth="0"/>
                </svg>
                <div id="projectsArchiveButtonText">{archiveVisible ? "Close Projects Archive" : "View Projects Archive"}</div>
              </button>
            </VStack>
            <VStack id="projectsArchive">
              <VStack id="projectsArchiveVeil" position="absolute" width="100%" height="150%" marginTop={{ base: '-8rem', md: '-8.25rem' }} opacity="0" backgroundColor="rgb(0, 0, 0, 0.1)" zIndex="105"></VStack>
              <Box className="flexProjectsContainer">
                {projectsArchiveList.map((project) => (
                  <ProjectStack
                    smoother={props.smoother}
                    key={project.title}
                    id={project.id}
                    title={project.title}
                    year={project.year}
                    client={project.client}
                    description={project.description}
                    servicesCard={project.servicesCard}
                    services={project.services}
                    popover={project.popover}
                    image={project.image}
                    imageset={project.imageset}
                    bgImage={project.bgImage}
                    bgImageset={project.bgImageset}
                    fgImage={project.fgImage}
                    fgImageset={project.fgImageset}
                    video={project.video}
                    icon={project.icon}
                    html={project.html}
                    css={project.css}
                    react={project.react}
                    reactNative={project.reactNative}
                    next={project.next}
                    tailwind={project.tailwind}
                    three={project.three}
                    foundation={project.foundation}
                    flash={project.flash}
                    premiere={project.premiere}
                    aftereffects={project.aftereffects}
                    sqlite={project.sqlite}
                    link={project.link}
                    linktext={project.linktext}
                    bgColor={props.bgColor}
                    color={props.color}
                  />
                ))}
              </Box>
            </VStack>
          </Box>}

        </VStack>

        {/* <Box
          position="relative"
          bg="transparent"
          width="100%"
          // top="-0.01vw"
          marginTop="-1px"
          // marginBottom="-2px"
          zIndex="100"
          // border="2px solid yellow"
        >
          <Box
            height="5vw"
            width="100%"
            // marginBottom="3vw"
            backgroundColor="rgb(96, 0, 0, 0.9)"
            // backgroundColor="rgb(250, 255, 255, 0.95)"
            backdropFilter="blur(5px)"
            // clipPath="polygon(0 50%, 0 0, 100% 0, 100% 50%, 50% 100%)"
            clipPath="polygon(0% 45%, 0% 0%, 100% 0%, 100% 45%, 53% 85%, 50% 95%, 47% 85%)"
            // clipPath="polygon(0 50%, 0 0, 100% 0, 100% 50%, 90% 35%, 50% 100%, 10% 35%)"
            // clipPath="polygon(0 0, 100% 0, 100% 50%, 95% 55%, 85% 45%, 50% 100%, 15% 45%, 5% 55%, 0 50%)"
            webkit-clip-path="polygon(0% 45%, 0% 0%, 100% 0%, 100% 45%, 53% 85%, 50% 95%, 47% 85%)"
          />
        </Box> */}

      </VStack>
    </section>
  );
};
export default Projects;