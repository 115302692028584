import "./Bubble.css";
import { useState, useRef } from 'react';
/* import {
    Box,
} from "@chakra-ui/react"; */
import { gsap } from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, ScrollTrigger);

function Bubble(props) {

  const bubble = useRef();

  /* useGSAP(
    () => {

    },
    { dependencies: [props.color, props.noHover, props.portrait, props.under768], revertOnUpdate: true, scope: bubble }
  ); */

  return (
    <div id={props.id} className="bubble">

      <div className="bubbleVideo">
        {/* <img src={props.imagesrc} alt={props.alt}/> */}
        <video
          id={props.videoID}
          className="mediaWallAsset"
          src={props.video}
          typeof="video/mp4"
          playsInline
          muted
          autoPlay
          loop
          onLoadedData={() => {}}
        />
      </div>

      <div className="bubbleIcon">
        <img src={props.icon} alt={props.alt}/>
      </div>
      {/* <h1>{props.header}</h1> */}

    </div>
  );
};

export default Bubble;